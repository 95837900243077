import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grid-pacientes',
  templateUrl: './grid-pacientes.component.html',
  styles: []
})
export class GridPacientesComponent implements OnInit {

  public data: any[];
  public filterQuery = '';
  public rowsOnPage = 10;
  public sortBy = '';
  public sortOrder = 'asc';

  constructor(
    public _pacienteSrv: PacienteService,
    public router: Router
  ) { }

  ngOnInit() {
    this._pacienteSrv.consultar(0)
      .then(() => {
        this.data = this._pacienteSrv.data;
      });
  }

  add() {
    this.router.navigate(['/historial/historia/ficha-identificacion']);
  }

  editar(item: any) {
    this._pacienteSrv.paciente = item;
    this.router.navigate(['/historial/historia/ficha-identificacion']);
  }

}
