import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-index-expediente-clinico',
  templateUrl: './index-expediente-clinico.component.html',
  styles: []
})
export class IndexExpedienteClinicoComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    
  }

}
