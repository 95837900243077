import { Problema } from './antecedentes-heredo-familiares.class';

export class AntecedentesFamiliares {
    Paciente_Id: number;
    Id: number;
    Sin_Significativos: boolean;
    Antecedentes_Desconocidos: boolean;
    Enfermedades_Geneticas: boolean;
    Enfermedades_Geneticas_Especificar: string;
    Problemas: Problema[];

    constructor(
        Id: number,
        Sin_Significativos: boolean,
        Antecedentes_Desconocidos: boolean,
        Enfermedades_Geneticas: boolean,
        Enfermedades_Geneticas_Especificar: string,
        Problemas: Problema[]
    ) {
        this.Id = Id;
        this.Sin_Significativos = Sin_Significativos;
        this.Antecedentes_Desconocidos = Antecedentes_Desconocidos;
        this.Enfermedades_Geneticas = Enfermedades_Geneticas;
        this.Enfermedades_Geneticas_Especificar = Enfermedades_Geneticas_Especificar;
        this.Problemas = Problemas;
    }
}