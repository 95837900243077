import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { PacienteService } from 'src/app/services/paciente.service';

@Component({
  selector: 'app-form-nota-referencia',
  templateUrl: './form-nota-referencia.component.html',
  styles: []
})
export class FormNotaReferenciaComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 4;
  pagina = 1;
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;

  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogoSrv: CatalogoService
  ) { }

  ngOnInit() {
    
  }

  guardar(forma: NgForm) {
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
