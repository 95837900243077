import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class MensajeService {

    constructor(
    ) {
    }

    correctamente(mensaje: string) {
        return Swal.fire({
            position: 'center',
            type: 'success',
            title: mensaje,
            showConfirmButton: false,
            timer: 1500
        });
    }

    informacion(mensaje: string) {
        return Swal.fire({
            position: 'center',
            type: 'info',
            title: mensaje,
            showConfirmButton: true,
            confirmButtonText: 'Enterado'
        });
    }

    informacionAltoContraste(mensaje: string) {
        return Swal.fire({
            position: 'center',
            type: 'info',
            html: `<h3 style="font-weight: bold;">${mensaje}</h3>`,
            showConfirmButton: true,
            confirmButtonText: 'Enterado'
        });
    }

    mensaje(mensaje: string) {
        Swal.fire({
            position: 'center',
            type: 'warning',
            title: mensaje,
            showConfirmButton: false,
            timer: 1500
        });
    }

    confirmar(mensaje: string) {
        return Swal.fire({
            title: mensaje,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Regresar'
        });
    }

    cargando() {
        Swal.fire({
            title: 'Procesando...',
            // timer: 2000,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            onClose: () => {
            }
        }).then((result) => {
        });
    }

    cerrar() {
        Swal.close();
    }

    error() {
        Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al procesar la petición!',
        })
    }
}