import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';

@Component({
  selector: 'app-form-info-profesional',
  templateUrl: './form-info-profesional.component.html',
  styles: []
})
export class FormInfoProfesionalComponent implements OnInit {

  total = 1;
  pagina = 1;
  fileToUpload: File;

  constructor(
    public _pacienteSrv: PacienteService
    ) { }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
    this._pacienteSrv.upload(this.fileToUpload)
    .then((data: any) => {
        this._pacienteSrv.form_11.Fotografia = data.NombreFormateado;
        this._pacienteSrv.guardar_infoProfesional();
    });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

  mostrar(){
    console.log(this._pacienteSrv.form_11);
  }

  handleFile(files: FileList){
    this.fileToUpload = files.item(0);
  }
}
