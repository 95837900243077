import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from "../../../services/catalogo.service";
import { PacienteService } from '../../../services/paciente.service';
import { TerapiasAlternativas } from '../../../class/terapeutica-empleada.class';
import { myDatePickerOptions } from 'src/app/services/url.servicios';

@Component({
  selector: 'app-form-terapeutica-empleada',
  templateUrl: './form-terapeutica-empleada.component.html',
  styles: []
})
export class FormTerapeuticaEmpleadaComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  total = 1;
  pagina = 1;
  terapiasAlternativasCat = [];

  constructor(
    public _catalogoSrv: CatalogoService,
    public _pacienteSrv: PacienteService
  ) {
  }

  ngOnInit() {
    this._catalogoSrv.terapiasAlternativas()
    .then((data: any[]) => {
      this.terapiasAlternativasCat.push(...data);
    });
  }

  guardar() {
    this._pacienteSrv.guardar_terapeutica();
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

  mostrar(){
    console.log(this._pacienteSrv.form_9);
  }
}
