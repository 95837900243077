import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';

@Component({
  selector: 'app-form-diagnostico',
  templateUrl: './form-diagnostico.component.html',
  styles: []
})
export class FormDiagnosticoComponent implements OnInit {

  total = 1;
  pagina = 1;

  constructor(
    public _pacienteSrv: PacienteService
  ) { }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
    this._pacienteSrv.guardar_diagnostico();
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

  mostrar(){
    console.log(this._pacienteSrv.form_10);
  }
}
