
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVICIOS, OPTIONS, USUARIO, SESCALAS } from '../services/url.servicios';
import { map, timeoutWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MensajeService } from './mensajes.service';
import { Router } from '@angular/router';

@Injectable()
export class HistorialService {

    ficha: number = 1;

    constructor(
        public http: Http,
        public _mensajeSrv: MensajeService,
        public router: Router,
    ) {

    }

    escalas(Historia_Clinica_Id: number, Escala_Id: number, page: number, pageSize: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${SESCALAS}?Historia_Clinica_Id=${Historia_Clinica_Id}&Escala_Id=${Escala_Id}&page=${page}&pageSize=${pageSize}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.cerrar();
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    insertar_escala(item: any) {
        this._mensajeSrv.cargando();
        item.Historia_Clinica_Id = 1;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${SESCALAS}`;
            this.http.post(url, item, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (output.Resultado) {
                        this._mensajeSrv.correctamente(output.Mensaje);
                    }
                    resolve(output.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }
}