import { Headers, RequestOptions } from '@angular/http';
import { IMyDpOptions } from 'mydatepicker';
import { DialogOptions } from 'ng2-bootstrap-modal';
// import { DialogOptions } from 'ng2-bootstrap-modal';

export const URL_SERVICIOS = 'http://jorgejofi4-001-site1.itempurl.com/api/';
// export const URL_SERVICIOS = 'http://itzonyoc.azurewebsites.net/api/';
// export const URL_SERVICIOS = 'http://localhost:57047/api/';
export const URL_ESTADOS = 'https://itzonyoc.mx/www/escuelarest/index.php/Catalogo/Estados';
export const URL_MUNICIPIOS = 'https://itzonyoc.mx/www/escuelarest/index.php/Catalogo/Municipios';
export const USUARIO = 'Usuario';

export const PACIENTE = 'Paciente';
export const ANTECEDENTES = 'AntecedentesFamiliares';
export const RESULTADOSANALISIS = "ResultadosAnalisis";
export const SESCALAS = 'Escalas';
export const TERAPEUTICAEMPLEADA = 'TerapeuticaEmpleada';
export const DIAGNOSTICO = 'Diagnostico';
export const INFORMACIONPROFESIONAL = "InformacionProfesional";
export const INTERROGATORIO = "InterrogatorioAparatosSistemas";
export const EXPLORACIONFISICA = 'ExploracionFisica';
export const PADECIMIENTOACTUAL = 'PadecimientoActual';
export const HOJAFRONTAL = 'HojaFrontal';
export const ANTECEDENTESNOPATOLOGICOS = 'AntecedentesNoPatologicos';
export const FICHACLINICA = "FichaClinica";
export const NOTAEVOLUCION = "NotaEvolucion";
export const NOTAEGRESO = "NotaEgreso";


export const PARENTESCO = 'CatParentesco';
export const ESCOLARIDAD = 'CatEscolaridad';
export const TIPOALIMENTOS = 'CatTipoAlimentos';
export const TIPOCONSULTAS = 'CatTipoConsultas';
export const RELIGION = 'CatReligion';
export const LUGAR = 'CatLugarElaboracion';
export const PROBLEMA = 'CatProblema';
export const ESCALAS = 'CatEscalas';
export const ESCALASDET = 'CatEscalasDetalle';
export const TERAPIASALTERNATIVAS = 'CatTerapiasAlternativas';
export const DESARROLLOPSICOMOTOR = 'CatDesarrolloPsicomotor';
export const TIPOSSERVICIO = 'CatTiposServicio';

export const FORMATO = 'Formato';
export const FILES = 'Files';

export const OPTIONS = new RequestOptions({
    headers: new Headers({
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
    })
});

export interface ConfirmModel {
    title: string;
    message: string;
    data: any;
}

export const modalOptions: DialogOptions = {
    backdropColor: 'rgba(35, 35, 35, 0.8)'
};

export const myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    dayLabels: { su: 'Do', mo: 'Lu', tu: 'Ma', we: 'Mi', th: 'Ju', fr: 'Vi', sa: 'Sa' },
    monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
    showTodayBtn: false,
    sunHighlight: true,
    disableSince: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate() + 1
    }
};