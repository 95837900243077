import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { UsuarioService } from './usuario.service';

@Injectable()
export class AdminAuthGuardService implements CanActivate {

    constructor(
        public router: Router,
        private _ss: UsuarioService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this._ss.isAdminAuthenticated()) {
            this.router.navigate(['/']);
        }
        return this._ss.isAdminAuthenticated();
    }

}
