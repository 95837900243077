import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-ed',
  templateUrl: './form-ed.component.html',
  styles: []
})
export class FormEDComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 2;
  pagina = 1;
  data = [];
  data_1 = [];
  result = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this._catalogoSrv.escalas_detalle('ED', 1, 10)
      .then((data: any[]) => {
        this.data = [];
        this.data_1 = [];

        this.data.push(...data.filter(x => !x.Dependiente));
        this.data_1.push(...data.filter(x => x.Dependiente));
        // this.recargar();
      });

    this._historialSrv.escalas(1, 6, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data); console.log(this.result);
      });
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this.data.forEach(element => {
      this.seleccionado.Puntaje += Number(element.Puntaje);
    });
    this.data_1.forEach(element => {
      this.seleccionado.Puntaje += Number(element.Puntaje);
    });
  }

  existen_dos() {
    let conteo = 0;
    this.data.forEach(element => {
      if (Number(element.Puntaje) > 0) {
        conteo++;
      }
    });
    return conteo;
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 6;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
