export class HojaFrontal {
    Expediente_Medico_Id: number;
    Persona_Id: number;
    Nombre: string;
    Edad: number;
    Fecha_Nacimiento: string;
    Estado_Civil: string;
    Ocupacion: string;
    Religion: string;
    Genero: string;
    Escolaridad: string;
    Domicilio: string;
    Telefono: string;
    Tipo_Sangre: string;
    Alergias: string;
    Fecha_Ingreso_1: any;
    Fecha_Ingreso_2: any;
    Fecha_Egreso: any;
    Equipo_Multidisciplinario: string;
    Lugar_Elaboracion_Id: number;
    Domicilio_Ubicacion: string;
    Asistencias: Asistencia[];
    Consultas: Consulta[];
    Diagnosticos: DiagnosticoFrontal[];

    constructor(Expediente_Medico_Id: number, Persona_Id: number, Nombre: string, Edad: number, Fecha_Nacimiento: string, Estado_Civil: string, Ocupacion: string, Religion: string, Genero: string, Escolaridad: string, Domicilio: string, Telefono: string, Tipo_Sangre: string, Alergias: string, Fecha_Ingreso_1: any, Fecha_Ingreso_2: any, Fecha_Egreso: any, Equipo_Multidisciplinario: string, Lugar_Elaboracion_Id: number, Domicilio_Ubicacion: string, Asistencias: Asistencia[], Consultas: Consulta[], Diagnosticos: DiagnosticoFrontal[]) {
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Persona_Id = Persona_Id;
        this.Nombre = Nombre;
        this.Edad = Edad;
        this.Fecha_Nacimiento = Fecha_Nacimiento;
        this.Estado_Civil = Estado_Civil;
        this.Ocupacion = Ocupacion;
        this.Religion = Religion;
        this.Genero = Genero;
        this.Escolaridad = Escolaridad;
        this.Domicilio = Domicilio;
        this.Telefono = Telefono;
        this.Tipo_Sangre = Tipo_Sangre;
        this.Alergias = Alergias;
        this.Fecha_Ingreso_1 = Fecha_Ingreso_1;
        this.Fecha_Ingreso_2 = Fecha_Ingreso_2;
        this.Fecha_Egreso = Fecha_Egreso;
        this.Equipo_Multidisciplinario = Equipo_Multidisciplinario;
        this.Lugar_Elaboracion_Id = Lugar_Elaboracion_Id;
        this.Domicilio_Ubicacion = Domicilio_Ubicacion;
        this.Asistencias = Asistencias;
        this.Consultas = Consultas;
        this.Diagnosticos = Diagnosticos;
    }

}

export class Consulta {
    Id: number;
    Expediente_Medico_Id: number;
    Tipo_Consulta_Id: number;
    Tipo_Consulta_Dsc: string;
    Fecha_Alta: any;
    Motivo_Alta: string;
    Fecha_Baja: any;
    Motivo_Baja: string;

    constructor(Id: number, Expediente_Medico_Id: number, Tipo_Consulta_Id: number, Tipo_Consulta_Dsc: string, Fecha_Alta: any, Motivo_Alta: string, Fecha_Baja: any, Motivo_Baja: string) {
        this.Id = Id;
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Tipo_Consulta_Id = Tipo_Consulta_Id;
        this.Tipo_Consulta_Dsc = Tipo_Consulta_Dsc;
        this.Fecha_Alta = Fecha_Alta;
        this.Motivo_Alta = Motivo_Alta;
        this.Fecha_Baja = Fecha_Baja;
        this.Motivo_Baja = Motivo_Baja;
    }
}

export class Asistencia {
    Id: number;
    Expediente_Medico_Id: number;
    Fecha: any;
    Motivo: string;
    EsAsistencia: boolean;

    constructor(Id: number, Expediente_Medico_Id: number, Fecha: any, Motivo: string, EsAsistencia: boolean) {
        this.Id = Id;
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Fecha = Fecha;
        this.Motivo = Motivo;
        this.EsAsistencia = EsAsistencia;
    }
}

export class DiagnosticoFrontal {
    Id: number;
    Expediente_Medico_Id: number;
    Diagnostico: string;
    Fecha: any;

    constructor(Id: number, Expediente_Medico_Id: number, Diagnostico: string, Fecha: any) {
        this.Id = Id;
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Diagnostico = Diagnostico;
        this.Fecha = Fecha;
    }

}