import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-eaamt',
  templateUrl: './form-eaamt.component.html',
  styles: []
})
export class FormEAAMTComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 3;
  pagina = 1;
  data = [];
  data_1 = [];
  result = [];
  respaldo = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        x.Id = element.Id;
        x.Pregunta = element.Pregunta;
        x.Puntaje = element.Puntaje;
      } else {
        this.respaldo.push(element);
      }
    });

    this._catalogoSrv.escalas_detalle('EAAMT', this.pagina, 13)
      .then((data: any[]) => {
        this.data = [];
        this.data.push(...data);
        // this.data.forEach(element => {
        //   element.Puntaje = [
        //     { Value: 1, Seleccionado: true },
        //     { Value: 2, Seleccionado: false },
        //     { Value: 3, Seleccionado: false },
        //     { Value: 4, Seleccionado: false },
        //     { Value: 5, Seleccionado: false },
        //   ];
        // });
        this.recargar();
        this.suma();
      });

    this._historialSrv.escalas(1, 16, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data);
      });
  }

  recargar() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        element.Id = x.Id;
        element.Pregunta = x.Pregunta;
        element.Puntaje = x.Puntaje;
      } else {
        this.respaldo.push(element);
      }
    });
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this.respaldo.forEach(element => {
      if (element.Puntaje > 0) {
        console.log(element.Puntaje);
        this.seleccionado.Puntaje += Number(element.Puntaje);
      }
    });
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 16;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.respaldo = [];
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
    this.paginador();
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
    this.paginador();
  }

}
