import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ConfirmModel } from '../../services/url.servicios';
import { PacienteService } from '../../services/paciente.service';
import { NgForm } from '@angular/forms';
import { NotaEvolucion } from '../../class/nota-evolucion.class';
import { Archivo } from '../../class/resultados-analisis.class';
import { ExpedienteService } from '../../services/expediente.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styles: []
})
export class LogoComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  data: any;
  fileToUpload: File = null;
  tipo: number;
  archivo: any;
  archivos = [];

  constructor(
    dialogService: DialogService,
    public _pacienteSrv: PacienteService,
    public _expedienteSrv: ExpedienteService
  ) {
    super(dialogService);
  }

  ngOnInit() { }

  agregar(forma: NgForm) {
    this._pacienteSrv.upload(this.fileToUpload)
      .then((data: any) => {
        switch (this.title) {
          case 'Nota de evolución':
            this._expedienteSrv.form_3.Logo = data.NombreFormateado;
            this._expedienteSrv.guardar_nota_evolucion()
              .then(() => {
                this.archivo = undefined;
                this.close();
                // this.ngOnInit();
              });
            break;
          case 'Nota de egreso':
            this._expedienteSrv.form_5.Logo = data.NombreFormateado;
            this._expedienteSrv.guardar_nota_egreso()
              .then(() => {
                this.archivo = undefined;
                this.close();
                // this.ngOnInit();
              });
            break;
          default:
            break;
        }
      });
  }

  ver(item: Archivo) {
    this._pacienteSrv.download(item)
      .then((data: any) => {
        if (data.Resultado && data.Path !== undefined && data.Path !== null && data.Path !== '') {
          window.open(data.Path, '_blank');
        }
      });
  }

  cerrar() {
    this.result = true;
    this.close();
  }

  handleFile(files: FileList) {
    if (files.item && files.item.length > 0) {
      this.fileToUpload = files.item(0);
    }
  }
}
