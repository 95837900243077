import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-index-estudio-socioeconomico',
  templateUrl: './index-estudio-socioeconomico.component.html',
  styles: []
})
export class IndexEstudioSocioeconomicoComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    
  }

}
