import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { Asistencia, Consulta, DiagnosticoFrontal } from 'src/app/class/hoja-frontal.class';

@Component({
  selector: 'app-form-hoja-frontal',
  templateUrl: './form-hoja-frontal.component.html',
  styles: []
})
export class FormHojaFrontalComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  total = 3;
  pagina = 1;

  asistencias: Asistencia[] = [];
  diagonosticos: DiagnosticoFrontal[] = [];

  constructor(
    public _expedienteSrv: ExpedienteService,
    public _catalogoSrv: CatalogoService
  ) {


  }

  ngOnInit() {
    this._expedienteSrv.consultar_hojafrontal(2)
      .then((data: any) => {
        console.log(data);
        this._expedienteSrv.form_1 = data;
        if (this._expedienteSrv.form_1.Consultas === undefined || this._expedienteSrv.form_1.Consultas === null) {
          this._expedienteSrv.form_1.Consultas = [];
        }

        if (this._expedienteSrv.form_1.Asistencias) {
          this.asistencias.push(...this._expedienteSrv.form_1.Asistencias);
          this.asistencias.forEach(element => {
            element.EsAsistencia = Boolean(element.EsAsistencia);
          });

          let inicio = this.asistencias.length;
          for (let index = inicio; index < 5; index++) {
            this.asistencias.push(new Asistencia(index, 0, null, '', null));
          }
        } else {
          for (let index = 0; index < 5; index++) {
            this.asistencias.push(new Asistencia(index, 0, null, '', null));
          }
        }

        if (this._expedienteSrv.form_1.Diagnosticos) {
          this.diagonosticos.push(...this._expedienteSrv.form_1.Diagnosticos);

          let inicio = this.diagonosticos.length;
          for (let index = inicio; index < 6; index++) {
            this.diagonosticos.push(new DiagnosticoFrontal(index, 0, '', null));
          }
        } else {
          for (let index = 0; index < 6; index++) {
            this.diagonosticos.push(new DiagnosticoFrontal(index, 0, '', null));
          }
        }

        this._catalogoSrv.tipo_consultas()
          .then((data: [], ) => {
            data.forEach((element: any, index) => {
              let existe = this._expedienteSrv.form_1.Consultas.find(x => x.Tipo_Consulta_Id === element.Id);
              if (existe) {
              } else {
                this._expedienteSrv.form_1.Consultas.push(new Consulta(index, 0, element.Id, element.Descripcion, null, '', null, ''));
              }
            });
          });
      });
  }

  guardar(forma: NgForm) {
    this._expedienteSrv.form_1.Asistencias = [];
    this._expedienteSrv.form_1.Asistencias.push(...this.asistencias.filter(x => x.Fecha));

    this._expedienteSrv.form_1.Diagnosticos = [];
    this._expedienteSrv.form_1.Diagnosticos.push(...this.diagonosticos.filter(x => x.Diagnostico.length > 0));

    this._expedienteSrv.guardar_hojafrontal()
      .then(() => {
        this.asistencias = [];
        this.diagonosticos = [];
        this.ngOnInit();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
