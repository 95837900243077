import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';


@Component({
  selector: 'app-form-exploracion-fisica',
  templateUrl: './form-exploracion-fisica.component.html',
  styles: []
})
export class FormExploracionFisicaComponent implements OnInit {

  total = 3;
  pagina = 1;

  constructor(
    public _pacienteSrv: PacienteService
  ) { }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
    console.log(this._pacienteSrv.form_7);
    
    this._pacienteSrv.guardar_exploracion()
    .then((Id: number) => {
      // this._pacienteSrv.consultar(Id)
      // .then((data: any[]) => {
      //   if(data.length > 0) {
      //     this._pacienteSrv.paciente = data[0];
      //   }
      // });
    });
  }

  validar(forma: NgForm): boolean {
    // console.log(forma)
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
