export class NotaEgreso {
    Expediente_Medico_Id: number;
    Persona_Id: number;
    Fecha: any;
    Hora: string;
    Nombre: string;
    Edad: number;
    Sexo_Id: number;
    CURP: string;
    Habitacion: string;
    Servicio: string;
    Diagnosticos_Ingreso: string;
    Diagnosticos_Egreso: string;
    Fecha_Ingreso: any;
    Fecha_Egreso: any;
    Motivo_Egreso: string;
    Lugar_Elaboracion_Id: number;
    Resumen_Actual: string;
    Vigilancia_Ambulatoria: string;
    Manejo_Intrahospitalaria: string;
    Pronostico: string;
    Problemas_Pendientes: string;
    Causas_Muerte: string;
    Manejo_Tratamiento: string;
    Profesionales: Profesionales[];
    Logo: string;

    constructor(Expediente_Medico_Id: number, Persona_Id: number, Fecha: any, Hora: string, Nombre: string, Edad: number, Sexo_Id: number, CURP: string, Habitacion: string, Servicio: string, Diagnosticos_Ingreso: string, Diagnosticos_Egreso: string, Fecha_Ingreso: any, Fecha_Egreso: any, Motivo_Egreso: string, Lugar_Elaboracion_Id: number, Resumen_Actual: string, Vigilancia_Ambulatoria: string, Manejo_Intrahospitalaria: string, Pronostico: string, Problemas_Pendientes: string, Causas_Muerte: string, Manejo_Tratamiento: string, Profesionales: Profesionales[]) {
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Persona_Id = Persona_Id;
        this.Fecha = Fecha;
        this.Hora = Hora;
        this.Nombre = Nombre;
        this.Edad = Edad;
        this.Sexo_Id = Sexo_Id;
        this.CURP = CURP;
        this.Habitacion = Habitacion;
        this.Servicio = Servicio;
        this.Diagnosticos_Ingreso = Diagnosticos_Ingreso;
        this.Diagnosticos_Egreso = Diagnosticos_Egreso;
        this.Fecha_Ingreso = Fecha_Ingreso;
        this.Fecha_Egreso = Fecha_Egreso;
        this.Motivo_Egreso = Motivo_Egreso;
        this.Lugar_Elaboracion_Id = Lugar_Elaboracion_Id;
        this.Resumen_Actual = Resumen_Actual;
        this.Vigilancia_Ambulatoria = Vigilancia_Ambulatoria;
        this.Manejo_Intrahospitalaria = Manejo_Intrahospitalaria;
        this.Pronostico = Pronostico;
        this.Problemas_Pendientes = Problemas_Pendientes;
        this.Causas_Muerte = Causas_Muerte;
        this.Manejo_Tratamiento = Manejo_Tratamiento;
        this.Profesionales = Profesionales;
    }

}

export class Profesionales {
    Id: number;
    Historia_Clinica_Id: number;
    Profesional: string;

    constructor(Id: number, Historia_Clinica_Id: number, Profesional: string) {
        this.Id = Id;
        this.Historia_Clinica_Id = Historia_Clinica_Id;
        this.Profesional = Profesional;
    }

}