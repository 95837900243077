import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-esgb',
  templateUrl: './form-esgb.component.html',
  styles: []
})
export class FormESGBComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 2;
  pagina = 1;
  data = [];
  data_1 = [];
  result = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this._catalogoSrv.escalas_detalle('ESGB', 1, 10)
      .then((data: any[]) => {
        this.data = [];
        this.data_1 = [];

        this.data.push(...data.filter(x => Number(x.Tipo) === 1));
        this.data_1.push(...data.filter(x => Number(x.Tipo) === 2));
        // this.recargar();
      });

    this._historialSrv.escalas(1, 9, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data); console.log(this.result);
      });
  }

  seleccionar(item: any, i: number, j: number) {
    console.log(item);

    item[i].forEach(element => {
      element.Seleccionado = false;
    });
    item[i][j].Seleccionado = !item[i][j].Seleccionado;
    this.suma();
    // this.seleccionado = item;
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this._catalogoSrv.sgb.forEach(element => {
      element.forEach(item => {
        if (item.Seleccionado) {
          this.seleccionado.Puntaje += Number(item.Puntaje);
        }
      });
    });
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 9;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
