export class Interrogatorio {
    Historia_Clinica_Id: number;
    Peso_Habitual: string;
    Anamnesis: Anamnesis[];

    constructor(
        Historia_Clinica_Id: number,
        Peso_Habitual: string,
        Anamnesis: Anamnesis[]
    ) {
        this.Historia_Clinica_Id = Historia_Clinica_Id;
        this.Peso_Habitual = Peso_Habitual;
        this.Anamnesis = Anamnesis;
    }
}

export class Anamnesis {
    Id: number;
    Historial_Clinico_Id: number;
    Descripcion: string;
    Tipo_Anamenesis_Id: number;
    Tipo_Anamenesis_Dsc: string;
    Dolor: boolean;
    _Detalle: string;
    _SiNo: boolean;
    _Especifique: string;
    Anamensis_Caracteristicas: AnamensisCaracteristicas[];

    constructor(
        Id: number,
        Historial_Clinico_Id: number,
        Descripcion: string,
        Tipo_Anamenesis_Id: number,
        Tipo_Anamenesis_Dsc: string,
        Dolor: boolean,
        Anamensis_Caracteristicas: AnamensisCaracteristicas[]
    ) {
        this.Id = Id;
        this.Historial_Clinico_Id = Historial_Clinico_Id;
        this.Descripcion = Descripcion;
        this.Tipo_Anamenesis_Id = Tipo_Anamenesis_Id;
        this.Tipo_Anamenesis_Dsc = Tipo_Anamenesis_Dsc;
        this.Dolor = Dolor;
        this.Anamensis_Caracteristicas = Anamensis_Caracteristicas;
    }
}

export class AnamensisCaracteristicas {
    Id: number;
    Descripcion: string;

    constructor(
        Id: number,
        Descripcion: string
    ) {
        this.Id = Id;
        this.Descripcion = Descripcion;
    }
}