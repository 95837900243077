import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-pacientes',
  templateUrl: './index-pacientes.component.html',
  styles: []
})
export class IndexPacientesComponent implements OnInit {

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }

  link(id: number) {
    switch (id) {
      case 1:
        this.router.navigate(['/historial/historia/ficha-identificacion']);
        break;
    }
  }

}
