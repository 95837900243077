import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { Problema } from 'src/app/class/antecedentes-heredo-familiares.class';

@Component({
  selector: 'app-form-antecedentes-heredo-familiares',
  templateUrl: './form-antecedentes-heredo-familiares.component.html',
  styles: []
})
export class FormAntecedentesHeredoFamiliaresComponent implements OnInit {

  total = 5;
  pagina = 1;
  problemas: Problema[] = [];

  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogoSrv: CatalogoService
  ) {
    this.paginador();
  }

  paginador() {
    this.problemas.forEach(element => {
      let x = this._pacienteSrv.form_2.Problemas.find(x => x.Id === element.Id);
      if (x) {
        x.Familiar = (element.Familiar);
        x.sEspecificar = element.sEspecificar;
        x.Vive = element.Vive;
        x.Lugar = element.Lugar;
        x.Edad_Fallecimiento = element.Edad_Fallecimiento;
      } else {
        this._pacienteSrv.form_2.Problemas.push(
          new Problema(
            element.Id,
            element.Descripcion,
            (element.Familiar),
            element.Especificar,
            element.sEspecificar,
            element.Vive,
            element.Lugar,
            element.Edad_Fallecimiento
          ));
      }
    });

    this._catalogoSrv.problemas(this.pagina, 10)
      .then((data: Problema[]) => {
        this.problemas = [];
        this.problemas.push(...data);

        this.recargar();
      });
  }

  recargar() {
    this.problemas.forEach(element => {
      let x = this._pacienteSrv.form_2.Problemas.find(x => x.Id === element.Id);
      if (x) {
        element.Familiar = (x.Familiar);
        element.sEspecificar = x.sEspecificar;
        element.Vive = x.Vive;
        element.Lugar = x.Lugar;
        element.Edad_Fallecimiento = x.Edad_Fallecimiento;
      } else if (element.Familiar > 0) {
        this._pacienteSrv.form_2.Problemas.push(
          new Problema(
            element.Id,
            element.Descripcion,
            (element.Familiar),
            element.Especificar,
            element.sEspecificar,
            element.Vive,
            element.Lugar,
            element.Edad_Fallecimiento
          ));
      }
    });
  }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
    this.recargar();
    this._pacienteSrv.guardar_antecedentes()
      .then((Id: number) => {
        this._pacienteSrv.consultar(Id)
          .then((data: any[]) => {
            if (data.length > 0) {
              this._pacienteSrv.paciente = data[0];
            }
          });
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }

    this.paginador();
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }

    this.paginador();
  }

}
