import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions, modalOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { PacienteService } from 'src/app/services/paciente.service';
import { ExpedienteService } from '../../../services/expediente.service';
import { LogoComponent } from '../../logo/logo.component';
import { DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-form-nota-egreso',
  templateUrl: './form-nota-egreso.component.html',
  styles: []
})
export class FormNotaEgresoComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 3;
  pagina = 1;
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;
  logo: string;

  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogoSrv: CatalogoService,
    public _expedienteSrv: ExpedienteService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this._expedienteSrv.consultar_nota_egreso(2)
      .then((data: any) => {
        this._expedienteSrv.form_5 = data.Data;
        // data.Data.Signos_Vitales = [data.Data.Signos_Vitales];
        // if (data.Data.Signos_Vitales) {
        //   this.signosVitalesData = [];
        //   this.signosVitalesData.push(...data.Data.Signos_Vitales);
        // }

        // // data.Data.Diagnosticos__Actuales = [data.Data.Diagnosticos_Actuales];
        // this.diagnosticosData = [];
        // this.diagnosticosData.push(...data.Data.Diagnosticos_Actuales);

        this.logo = data.Data.Logo;
      });
  }

  verArchivos(titulo: string) {
    this.dialogService.addDialog(LogoComponent, {
      title: `${titulo}`,
      message: '',
      data: { titulo }
    }, modalOptions)
      .subscribe((isConfirmed: any) => {
        this.ngOnInit();
      });
  }

  guardar(forma: NgForm) {
    this._expedienteSrv.guardar_nota_egreso()
    .then(()=>{
      this.ngOnInit;
    });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
