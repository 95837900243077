import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-form-emdcp',
  templateUrl: './form-emdcp.component.html',
  styles: []
})
export class FormEMDCPComponent implements OnInit {

  total = 1;
  pagina = 1;

  constructor() { }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
