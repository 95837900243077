import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { ConfirmModel } from 'src/app/services/url.servicios';
import { NgForm } from '@angular/forms';
import { PacienteService } from 'src/app/services/paciente.service';
import { ResultadosAnalisis, Archivo } from 'src/app/class/resultados-analisis.class';

@Component({
  selector: 'app-form-archivo',
  templateUrl: './form-archivo.component.html',
  styles: []
})
export class FormArchivoComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  data: any;
  fileToUpload: File = null;
  tipo: number;
  archivo: any;
  archivos = [];

  constructor(
    dialogService: DialogService,
    public _paciente: PacienteService
  ) {
    super(dialogService);
  }

  ngOnInit() {
    this._paciente.consultar_archivos(1, this.title)
      .then((data: any) => {
        this.archivos = [];
        this.archivos.push(...data[0].Resultados_Previos);
      });
  }

  agregar(forma: NgForm) {
    this._paciente.upload(this.fileToUpload)
      .then((data: any) => {
        let archivo = [new Archivo(0, 1, this.data.titulo, '', data.NombreFormateado, data.Path)];
        this._paciente.form_8 = new ResultadosAnalisis(archivo, null, null, null, null, null);
        this._paciente.form_8.Historia_Clinica_Id = 1;
        this._paciente.guardar_resultados()
          .then(() => {
            this.archivo = undefined;
            this.ngOnInit();
          });
      });
  }

  ver(item: Archivo) {
    this._paciente.download(item)
      .then((data: any) => {
        if (data.Resultado && data.Path !== undefined && data.Path !== null && data.Path !== '') {
          window.open(data.Path, '_blank');
        }
      });
  }

  cerrar() {
    this.result = true;
    this.close();
  }

  handleFile(files: FileList) {
    if (files.item && files.item.length > 0) {
      this.fileToUpload = files.item(0);
    }
  }

}
