import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { IndexHistorialClinicoComponent } from './components/historial/index-historial-clinico/index-historial-clinico.component';
import { PanelHistorialClinicoComponent } from './components/historial/panel-historial-clinico/panel-historial-clinico.component';
import { IndexPacientesComponent } from './components/historial/index-pacientes/index-pacientes.component';
import { AdminAuthGuardService } from './services/admin.auth-guard.service';
import { IndexHistoriaClinicaComponent } from './components/historial/index-historia-clinica/index-historia-clinica.component';
import { FormFichaIdentificacionComponent } from './components/historial/form-ficha-identificacion/form-ficha-identificacion.component';
import { FormAntecedentesHeredoFamiliaresComponent } from './components/historial/form-antecedentes-heredo-familiares/form-antecedentes-heredo-familiares.component';
import { FormAntecedPersNoPatologicosComponent } from './components/historial/form-anteced-pers-no-patologicos/form-anteced-pers-no-patologicos.component';
import { FormAntecedPersPatologicosComponent } from './components/historial/form-anteced-pers-patologicos/form-anteced-pers-patologicos.component';
import { FormPadecimientoActualComponent } from './components/historial/form-padecimiento-actual/form-padecimiento-actual.component';
import { FormInterrogatorioAparatosSistemasComponent } from './components/historial/form-interrogatorio-aparatos-sistemas/form-interrogatorio-aparatos-sistemas.component';
import { GridPacientesComponent } from './components/paciente/grid-pacientes/grid-pacientes.component';
import { FormInfoProfesionalComponent } from './components/historial/form-info-profesional/form-info-profesional.component';
import { FormDiagnosticoComponent } from './components/historial/form-diagnostico/form-diagnostico.component';
import { FormTerapeuticaEmpleadaComponent } from './components/historial/form-terapeutica-empleada/form-terapeutica-empleada.component';
import { FormResultadosAnalisisComponent } from './components/historial/form-resultados-analisis/form-resultados-analisis.component';
import { FormExploracionFisicaComponent } from './components/historial/form-exploracion-fisica/form-exploracion-fisica.component';
import { IndexEscalasComponent } from './components/escalas/index-escalas/index-escalas.component';
import { FormIndiceComponent } from './components/escalas/form-indice/form-indice.component';
import { FormEAComponent } from './components/escalas/form-ea/form-ea.component';
import { FormEAAMTComponent } from './components/escalas/form-eaamt/form-eaamt.component';
import { FormEAPNComponent } from './components/escalas/form-eapn/form-eapn.component';
import { FormEDComponent } from './components/escalas/form-ed/form-ed.component';
import { FormEDAMComponent } from './components/escalas/form-edam/form-edam.component';
import { FormEDBComponent } from './components/escalas/form-edb/form-edb.component';
import { FormEDFComponent } from './components/escalas/form-edf/form-edf.component';
import { FormEECOGComponent } from './components/escalas/form-eecog/form-eecog.component';
import { FormEEDMONTComponent } from './components/escalas/form-eedmont/form-eedmont.component';
import { FormEEPComponent } from './components/escalas/form-eep/form-eep.component';
import { FormEKComponent } from './components/escalas/form-ek/form-ek.component';
import { FormEMComponent } from './components/escalas/form-em/form-em.component';
import { FormEMDCPComponent } from './components/escalas/form-emdcp/form-emdcp.component';
import { FormEMMComponent } from './components/escalas/form-emm/form-emm.component';
import { FormEMMCLComponent } from './components/escalas/form-emmcl/form-emmcl.component';
import { FormENComponent } from './components/escalas/form-en/form-en.component';
import { FormEPDLComponent } from './components/escalas/form-epdl/form-epdl.component';
import { FormESCCZComponent } from './components/escalas/form-esccz/form-esccz.component';
import { FormESGBComponent } from './components/escalas/form-esgb/form-esgb.component';
import { FormEVAComponent } from './components/escalas/form-eva/form-eva.component';
import { IndexExpedienteClinicoComponent } from './components/expediente/index-expediente-clinico/index-expediente-clinico.component';
import { FormHojaFrontalComponent } from './components/expediente/form-hoja-frontal/form-hoja-frontal.component';
import { FormFichaClinicaComponent } from './components/expediente/form-ficha-clinica/form-ficha-clinica.component';
import { FormNotaEvolucionComponent } from './components/expediente/form-nota-evolucion/form-nota-evolucion.component';
import { FormHojaIndicacionesComponent } from './components/expediente/form-hoja-indicaciones/form-hoja-indicaciones.component';
import { FormPostOperatoriaComponent } from './components/expediente/form-post-operatoria/form-post-operatoria.component';
import { FormConsentimientoComponent } from './components/expediente/form-consentimiento/form-consentimiento.component';
import { FormNotaEnfermeriaComponent } from './components/expediente/form-nota-enfermeria/form-nota-enfermeria.component';
import { FormValoracionEnfermeriaComponent } from './components/expediente/form-valoracion-enfermeria/form-valoracion-enfermeria.component';
import { FormNotaEgresoComponent } from './components/expediente/form-nota-egreso/form-nota-egreso.component';
import { FormAvisoPrivacidadComponent } from './components/expediente/form-aviso-privacidad/form-aviso-privacidad.component';
import { FormEstudiosComponent } from './components/expediente/form-estudios/form-estudios.component';
import { FormNotaReferenciaComponent } from './components/expediente/form-nota-referencia/form-nota-referencia.component';
import { FormEgresoVoluntarioComponent } from './components/expediente/form-egreso-voluntario/form-egreso-voluntario.component';
import { FormRecuersoComponent } from './components/expediente/form-recuerso/form-recuerso.component';
import { FormAgregarComponent } from './components/expediente/form-agregar/form-agregar.component';
import { IndexEstudioSocioeconomicoComponent } from './components/socioeconomico/index-estudio-socioeconomico/index-estudio-socioeconomico.component';
import { FormESEComponent } from './components/socioeconomico/form-ese/form-ese.component';
import { FormFamiliogramaComponent } from './components/socioeconomico/form-familiograma/form-familiograma.component';
import { FormFIPComponent } from './components/socioeconomico/form-fip/form-fip.component';
import { FormFATComponent } from './components/socioeconomico/form-fat/form-fat.component';
import { FormFIPLComponent } from './components/socioeconomico/form-fipl/form-fipl.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'historial', component: IndexHistorialClinicoComponent,
    children: [
      { path: '', component: PanelHistorialClinicoComponent },
      { path: 'pacientes', component: GridPacientesComponent },
      {
        path: 'historia', component: IndexHistoriaClinicaComponent,
        children: [
          // { path: '**', pathMatch: 'full', redirectTo: 'ficha-identificacion' },
          { path: 'ficha-identificacion', component: FormFichaIdentificacionComponent },
          { path: 'antecedentes', component: FormAntecedentesHeredoFamiliaresComponent },
          { path: 'no-patologicos', component: FormAntecedPersNoPatologicosComponent },
          { path: 'patologicos', component: FormAntecedPersPatologicosComponent },
          { path: 'padecimiento', component: FormPadecimientoActualComponent },
          { path: 'interrogatorio', component: FormInterrogatorioAparatosSistemasComponent },
          { path: 'exploracion', component: FormExploracionFisicaComponent },
          { path: 'resultados', component: FormResultadosAnalisisComponent },
          { path: 'terapeutica', component: FormTerapeuticaEmpleadaComponent },
          { path: 'diagnostico', component: FormDiagnosticoComponent },
          { path: 'profesional', component: FormInfoProfesionalComponent },
        ]
      },
      {
        path: 'escalas', component: IndexEscalasComponent,
        children: [
          { path: 'indice', component: FormIndiceComponent },
          { path: 'ea', component: FormEAComponent },
          { path: 'eaamt', component: FormEAAMTComponent },
          { path: 'eapn', component: FormEAPNComponent },
          { path: 'ed', component: FormEDComponent },
          { path: 'edam', component: FormEDAMComponent },
          { path: 'edb', component: FormEDBComponent },
          { path: 'edf', component: FormEDFComponent },
          { path: 'eecog', component: FormEECOGComponent },
          { path: 'eedmont', component: FormEEDMONTComponent },
          { path: 'eep', component: FormEEPComponent },
          { path: 'ek', component: FormEKComponent },
          { path: 'em', component: FormEMComponent },
          { path: 'emdcp', component: FormEMDCPComponent },
          { path: 'emm', component: FormEMMComponent },
          { path: 'emmcl', component: FormEMMCLComponent },
          { path: 'en', component: FormENComponent },
          { path: 'epdl', component: FormEPDLComponent },
          { path: 'esccz', component: FormESCCZComponent },
          { path: 'esgb', component: FormESGBComponent },
          { path: 'eva', component: FormEVAComponent },
        ]
      },
      {
        path: 'expediente', component: IndexExpedienteClinicoComponent,
        children: [
          { path: 'hoja-frontal', component: FormHojaFrontalComponent },
          { path: 'ficha-clinica', component: FormFichaClinicaComponent },
          { path: 'nota-evolucion', component: FormNotaEvolucionComponent },
          { path: 'hoja-indicaciones', component: FormHojaIndicacionesComponent },
          { path: 'post-operatoria', component: FormPostOperatoriaComponent },
          { path: 'consentimiento', component: FormConsentimientoComponent },
          { path: 'nota-enfermeria', component: FormNotaEnfermeriaComponent },
          { path: 'valoracion-enfermeria', component: FormValoracionEnfermeriaComponent },
          { path: 'nota-egreso', component: FormNotaEgresoComponent },
          { path: 'aviso-privacidad', component: FormAvisoPrivacidadComponent },
          { path: 'estudios', component: FormEstudiosComponent },
          { path: 'nota-referencia', component: FormNotaReferenciaComponent },
          { path: 'egreso-voluntario', component: FormEgresoVoluntarioComponent },
          { path: 'recuerdo', component: FormRecuersoComponent },
          { path: 'agregar', component: FormAgregarComponent },
        ]
      },
      {
        path: 'socioeconomico', component: IndexEstudioSocioeconomicoComponent,
        children: [
          { path: 'ese', component: FormESEComponent },
          { path: 'familiograma', component: FormFamiliogramaComponent },
          { path: 'fip', component: FormFIPComponent },
          { path: 'fat', component: FormFATComponent },
          { path: 'fipl', component: FormFIPLComponent },
        ]
      }
    ]
  },
  //
  // {
  //     path: 'main', component: MainComponent, children: [
  //         { path: '', component: HomeComponent },
  //         { path: 'home', component: HomeComponent },
  //         { path: 'charts', component: ChartsComponent },
  //         { path: 'descargas', component: IndexDescargasComponent },
  //         { path: 'demografia', component: IndexDemografiaComponent },
  //         { path: 'interaccion', component: IndexInteraccionComponent },
  //         { path: 'usuario', component: IndexUsuarioComponent },
  //         { path: 'administrador', component: IndexAdministradorComponent }
  //     ]
  // },
  // { path: '**', pathMatch: 'full', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
