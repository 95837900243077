import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';

@Component({
  selector: 'app-menu-estudio-socioeconomico',
  templateUrl: './menu-estudio-socioeconomico.component.html',
  styles: []
})
export class MenuEstudioSocioeconomicoComponent implements OnInit {

  constructor(
    public _pacienteSrv: PacienteService,
  ) { }

  ngOnInit() {
  }

}
