import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { FormArchivoComponent } from '../../form-archivo/form-archivo.component';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { ExpedienteService } from '../../../services/expediente.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { modalOptions } from 'src/app/services/url.servicios';

@Component({
  selector: 'app-form-ficha-clinica',
  templateUrl: './form-ficha-clinica.component.html',
  styles: []
})
export class FormFichaClinicaComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 2;
  pagina = 1;
  sexo = ["M", "F"];
  encamado = ["True", "False"];
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;
  terapiasAlternativasCat = [];
  data: any;

  diagnosticosData =[];

  constructor(
    public _pacienteSrv: PacienteService,
    public _expedienteSrv: ExpedienteService,
    public _catalogoSrv: CatalogoService,
    private dialogService: DialogService
  ) {
      this._expedienteSrv.consultar_fichaclinica(2)
      .then((data: any) =>{
        console.log(data);
        data.Data.Diagnoticos = [data.Data.Diagnoticos];
        this.diagnosticosData.push(...data.Data.Diagnoticos);
        console.log(this.diagnosticosData);
      });

    this._catalogoSrv.terapiasAlternativas()
    .then((data: any[]) => {
      this.terapiasAlternativasCat.push(...data);
    });
   }

  ngOnInit() {
    this._expedienteSrv.consultar_fichaclinica(2)
    .then((data: any) =>{
      this.data = data.Data;
      this._expedienteSrv.form_2 = data.Data;
    });
  }

  verArchivos(titulo: string) {
    this.dialogService.addDialog(FormArchivoComponent, {
      title: `${titulo}`,
      message: '',
      data: { titulo }
    }, modalOptions)
      .subscribe((isConfirmed: any) => {
        this.ngOnInit();
      });
  }

  guardar(forma?: NgForm) {
    this._expedienteSrv.guardar_fichaclinica()
    .then(()=>{
      this.ngOnInit();
    });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
