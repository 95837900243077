export class ExploracionFisica {
    Historia_Clinica_Id: number;
    // Page 1
    Peso: string;
    Talla: string;
    PCef: string;
    PBrazo: string;
    PPierna: string;
    SegInf: string;
    SegSup: string;
    FC: string;
    FR: string;
    Temp: string;
    TA: string;
    IMC: string;
    PielFaneras: string;
    Cabeza: string;
    Ojos: string;
    Oidos: string;
    BocaFaringe: string;
    Cuello: string;
    Torax: string;
    ApResp: string;
    ApCard: string;
    TannerMamario: string;
    Abdomen: string;
    Extremidades: string;

    // Page 2
    Genitales: string;
    TannerGenital: string;
    AnoRecto: string;
    TannerPubico: string;
    VasPeriferico: string;
    Neurologico: string;
    ColVertebral: string;

    // Page 3
    Temperatura: string;
    Respiracion: string;
    Pulso: string;
    Tension_Arterial: string;

    constructor(
        Peso: string,
        Talla: string,
        PCef: string,
        PBrazo: string,
        PPierna: string,
        SegInf: string,
        SegSup: string,
        FC: string,
        FR: string,
        Temp: string,
        TA: string,
        IMC: string,
        PielFaneras: string,
        Cabeza: string,
        Ojos: string,
        Oidos: string,
        BocaFaringe: string,
        Cuello: string,
        Torax: string,
        ApResp: string,
        ApCard: string,
        TannerMamario: string,
        Abdomen: string,
        Extremidades: string,
        Genitales: string,
        TannerGenital: string,
        AnoRecto: string,
        TannerPubico: string,
        VasPeriferico: string,
        Neurologico: string,
        ColVertebral: string,
        Temperatura: string,
        Respiracion: string,
        Pulso: string,
        Tension_Arterial: string
    ) {
        this.Peso = Peso;
        this.Talla = Talla;
        this.PCef = PCef;
        this.PBrazo = PBrazo;
        this.PPierna = PPierna;
        this.SegInf = SegInf;
        this.SegSup = SegSup;
        this.FC = FC;
        this.FR = FR;
        this.Temp = Temp;
        this.TA = TA;
        this.IMC = IMC;
        this.PielFaneras = PielFaneras;
        this.Cabeza = Cabeza;
        this.Ojos = Ojos;
        this.Oidos = Oidos;
        this.BocaFaringe = BocaFaringe;
        this.Cuello = Cuello;
        this.Torax = Torax;
        this.ApResp = ApResp;
        this.ApCard = ApCard;
        this.TannerMamario = TannerMamario;
        this.Abdomen = Abdomen;
        this.Extremidades = Extremidades;
        this.Genitales = Genitales;
        this.TannerGenital = TannerGenital;
        this.AnoRecto = AnoRecto;
        this.TannerPubico = TannerPubico;
        this.VasPeriferico = VasPeriferico;
        this.Neurologico = Neurologico;
        this.ColVertebral = ColVertebral;
        this.Temperatura = Temperatura;
        this.Respiracion = Respiracion;
        this.Pulso = Pulso;
        this.Tension_Arterial = Tension_Arterial;
    }

}