
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVICIOS, OPTIONS, USUARIO, PARENTESCO, ESCOLARIDAD, RELIGION, LUGAR, URL_ESTADOS, URL_MUNICIPIOS, PROBLEMA, ESCALAS, ESCALASDET, TERAPIASALTERNATIVAS, TIPOALIMENTOS, TIPOCONSULTAS, DESARROLLOPSICOMOTOR, TIPOSSERVICIO } from '../services/url.servicios';
import { map, timeoutWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MensajeService } from './mensajes.service';
import { Router } from '@angular/router';
import { Usuario } from '../class/usuario.class';

@Injectable()
export class CatalogoService {

    constructor(
        public http: Http,
        public _mensajeSrv: MensajeService,
        public router: Router,
    ) {

    }

    SiNo = [{ Value: true, Dsc: 'Sí' }, { Value: false, Dsc: 'No' }];
    Puntajes = [
        { Value: 0, Dsc: 'Nunca' },
        { Value: 1, Dsc: 'Casi Nunca' },
        { Value: 2, Dsc: 'A veces' },
        { Value: 3, Dsc: 'Frecuentemente' },
        { Value: 4, Dsc: 'Casi Siempre' },
    ];
    PuntajesEMM = [
        { Value: 0, Dsc: '' },
        { Value: 5, Dsc: 'Respondió' },
    ];
    SiNoFA_NoDependiente = [{ Value: 1, Dsc: 'Sí' }, { Value: 0, Dsc: 'No' }];
    SiNoFA_Dependiente = [{ Value: 3, Dsc: 'Sí' }, { Value: 0, Dsc: 'No' }];
    edad_escolaridad = [
        { Puntaje: 20, Seleccionado: false },
        { Puntaje: 20, Seleccionado: false },
        { Puntaje: 20, Seleccionado: false },
        { Puntaje: 19, Seleccionado: false },
        { Puntaje: 18, Seleccionado: false },
        { Puntaje: 16, Seleccionado: false },
        { Puntaje: 24, Seleccionado: false },
        { Puntaje: 24, Seleccionado: false },
        { Puntaje: 25, Seleccionado: false },
        { Puntaje: 24, Seleccionado: false },
        { Puntaje: 23, Seleccionado: false },
        { Puntaje: 22, Seleccionado: false },
        { Puntaje: 28, Seleccionado: false },
        { Puntaje: 28, Seleccionado: false },
        { Puntaje: 27, Seleccionado: false },
        { Puntaje: 27, Seleccionado: false },
        { Puntaje: 26, Seleccionado: false },
        { Puntaje: 23, Seleccionado: false },
        { Puntaje: 29, Seleccionado: false },
        { Puntaje: 29, Seleccionado: false },
        { Puntaje: 28, Seleccionado: false },
        { Puntaje: 28, Seleccionado: false },
        { Puntaje: 27, Seleccionado: false },
        { Puntaje: 26, Seleccionado: false },
    ];
    nova = [
        [{ Value: 0, Dsc: 'Alerta', Seleccionado: true, Puntaje: 0 },
        { Value: 1, Dsc: 'Alerta', Seleccionado: false, Puntaje: 1 },
        { Value: 2, Dsc: 'Letárgico', Seleccionado: false, Puntaje: 2 },
        { Value: 3, Dsc: 'Coma', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'No', Seleccionado: true, Puntaje: 0 },
        { Value: 1, Dsc: 'No', Seleccionado: false, Puntaje: 1 },
        { Value: 2, Dsc: 'Urinaria o fecal importante', Seleccionado: false, Puntaje: 2 },
        { Value: 3, Dsc: 'Urinaria y fecal', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Completa', Seleccionado: true, Puntaje: 0 },
        { Value: 1, Dsc: 'Completa', Seleccionado: false, Puntaje: 1 },
        { Value: 2, Dsc: 'Limitación', Seleccionado: false, Puntaje: 2 },
        { Value: 3, Dsc: 'Inmóvil', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Correcta', Seleccionado: true, Puntaje: 0 },
        { Value: 1, Dsc: 'Ocasionalmente con ayuda', Seleccionado: false, Puntaje: 1 },
        { Value: 2, Dsc: 'Incompleta siempre con ayuda', Seleccionado: false, Puntaje: 2 },
        { Value: 3, Dsc: 'No ingesta oral sin', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Deambula', Seleccionado: true, Puntaje: 0 },
        { Value: 1, Dsc: 'Deambula con ayuda', Seleccionado: false, Puntaje: 1 },
        { Value: 2, Dsc: 'Deambula y siempre precisa ayuda', Seleccionado: false, Puntaje: 2 },
        { Value: 3, Dsc: 'No deambula, encamado', Seleccionado: false, Puntaje: 3 }],
    ];
    flacc = [
        [{ Value: 0, Dsc: 'Relajada Expresión Neutra', Seleccionado: true, Puntaje: 0 },
        { Value: 3, Dsc: 'Menos Marcado Intermitente', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Relajadas', Seleccionado: true, Puntaje: 0 },
        { Value: 3, Dsc: 'Relajada Expresión Neutra', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Acostado y quieto', Seleccionado: true, Puntaje: 0 },
        { Value: 3, Dsc: 'Hiperonicidad modeada o hiperonicidad moderada', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'No llora', Seleccionado: true, Puntaje: 0 },
        { Value: 3, Dsc: 'Consuelo después de un minuto de esfuerzo', Seleccionado: false, Puntaje: 3 }],

        [{ Value: 0, Dsc: 'Satisfecho', Seleccionado: true, Puntaje: 0 },
        { Value: 3, Dsc: 'Quejido', Seleccionado: false, Puntaje: 3 }],
    ];
    sgb = [
        [{ Value: 0, Dsc: 'Ninguno', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Duerme entre 5-10 minutos', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Duerme más de 10 min', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Marcado constante', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Menos marcado interminente', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Calmado, Relajado', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Agitación incesante o ninguna actividad', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Agitación moderada o actividad minuciosa', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Normal', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Hipertonicidad fuerte o hipotonicidad, flácido', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Hiperonicidad modeada o hipotonicidad moderada', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Normal', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Ninguno después de 2 minutos', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Consuelo después de un minuto de esfuerzo', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Consuelo dentro de un minuto', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Llanto vigoroso', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Quejido', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'No llora ni se queja', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: '>20% aumento', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: '10 - 20 % aumento', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Dentro de la normalidad', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: '>10 mm/Hg de aumento', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: '10 mm/Hg de aumento', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Dentro de la normalidad', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: 'Apnea o taqupnea', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: 'Pausas de apnea', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Dentro de la normalidad', Seleccionado: true, Puntaje: 0 }],

        [{ Value: 0, Dsc: '10% de aumento de FiO2', Seleccionado: false, Puntaje: 2 },
        { Value: 1, Dsc: '=al 10% de > aumento de FiO2', Seleccionado: false, Puntaje: 1 },
        { Value: 3, Dsc: 'Ningún aumento en FiO2', Seleccionado: true, Puntaje: 0 }],

    ];

    parentesco() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${PARENTESCO}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    escolaridad() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${ESCOLARIDAD}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    religion() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${RELIGION}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    elaboracion() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${LUGAR}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    estados() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_ESTADOS}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    if (!output.error) {
                        resolve(output.catalogo);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    municipios(Estado: string) {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_MUNICIPIOS}?Estado=${Estado}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    if (!output.error) {
                        resolve(output.catalogo);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    problemas(page: number, pageSize: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${PROBLEMA}?page=${page}&pageSize=${pageSize}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.cerrar();
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    escalas(page: number, pageSize: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${ESCALAS}?page=${page}&pageSize=${pageSize}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.cerrar();
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    escalas_detalle(tipo: string, page: number, pageSize: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${ESCALASDET}?tipo=${tipo}&page=${page}&pageSize=${pageSize}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.cerrar();
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    terapiasAlternativas() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${TERAPIASALTERNATIVAS}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json())
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    tipo_alimentos() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${TIPOALIMENTOS}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    tipos_servicios() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${TIPOSSERVICIO}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    desarrolloPsicomotor() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${DESARROLLOPSICOMOTOR}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    tipo_consultas() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${TIPOCONSULTAS}`;

            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => {
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

}