
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVICIOS, OPTIONS, PACIENTE, ANTECEDENTES, TERAPEUTICAEMPLEADA, DIAGNOSTICO, INFORMACIONPROFESIONAL, RESULTADOSANALISIS, FORMATO, EXPLORACIONFISICA, PADECIMIENTOACTUAL, FILES, INTERROGATORIO, ANTECEDENTESNOPATOLOGICOS } from '../services/url.servicios';
import { map, timeoutWith } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { MensajeService } from './mensajes.service';
import { Router } from '@angular/router';
import { FichaIdentificacion } from '../class/ficha-identificacion.class';
import { AntecedentesFamiliares } from '../class/antecedentes-familiares.class';
import { TerapeuticaEmpleada, TerapiasAlternativas } from '../class/terapeutica-empleada.class';
import { Diagnostico } from '../class/diagnostico.class';

import { ExploracionFisica } from '../class/exploracion-fisica.class';
import { PadecimientoActual } from '../class/padecimiento-actual.class';
import { InformacionProfesional } from '../class/informacion-profesional.class';
import { Interrogatorio } from '../class/interrogatorio.class';
import { ResultadosAnalisis, Archivo } from '../class/resultados-analisis.class';
import { Anamnesis } from '../class/interrogatorio.class';
import { AntecedentesNoPatologicos, DesarrolloPsicomotor, Alimentacion, ActividadesFamiliares, Inmunizacion } from '../class/antecedentes-no-patologicos.class';

@Injectable()
export class PacienteService {

    ficha: number = 1;
    data = [];

    form_1: FichaIdentificacion = new FichaIdentificacion(0, '', '', '', '', '', '', '', false, '', '', '', '', 0, '', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, '');
    form_2: AntecedentesFamiliares = new AntecedentesFamiliares(0, false, false, false, '', []);
    form_3: AntecedentesNoPatologicos = new AntecedentesNoPatologicos('', false, 0, 0, '', '', '', '', '', '', '', '', '', '', '', '', 0, '', 0, '', '', 0, 0, 0, 0, '', [], 0, '', '', 0, 0, '', '', '', '', '', 0, '', [], [], '', '', 0, 0, 0, false, 0, 0, false, '', false, false, 0, 0, false, false, false, false, '', false, [], '', '', [], false, 0, [], '', false, '', null, false, '', null, '', false, null, false, null, '', false, null, '', false, null, false, null);
    form: Interrogatorio = new Interrogatorio(0, "", []);
    form_5: PadecimientoActual = new PadecimientoActual('', []);
    form_6: Anamnesis = new Anamnesis(0, 0, '', 0, '', false, []);
    form_7: ExploracionFisica = new ExploracionFisica('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
    form_8: ResultadosAnalisis = new ResultadosAnalisis(null, null, null, null, null, []);
    form_9: TerapeuticaEmpleada = new TerapeuticaEmpleada('', '', '', '', []);
    form_10: Diagnostico = new Diagnostico('');
    form_11: InformacionProfesional = new InformacionProfesional(null, '', '', '');

    paciente: any;

    constructor(
        public http: Http,
        public _mensajeSrv: MensajeService,
        public router: Router,
    ) {
        for (let i = 0; i < 6; i++) {
            this.form_9.Terapias_Alternativas.push(new TerapiasAlternativas(0, null, '', ''));
        }

        for (let e = 0; e < 3; e++) {
            this.form_3.Actividades_Familiares.push(new ActividadesFamiliares(0, ''));
        }

        for (let a = 0; a < 11; a++) {
            this.form_3.Cartilla_Inmunizaciones.push(new Inmunizacion(0, null));
        }
    }

    consultar(Id: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${PACIENTE}?Id=${Id}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (Id === 0) {
                            this.data = [];
                            this.data.push(...data.Data);
                            resolve();
                        } else {
                            resolve(data.Data);
                        }
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    consultar_archivos(Historia_Clinica_Id: number, Tipo: string) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${RESULTADOSANALISIS}?Historia_Clinica_Id=${Historia_Clinica_Id}&Tipo=${Tipo}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        resolve(data.Data);
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar() {
        this._mensajeSrv.cargando();
        this.form_1.Fecha_Nacimiento = this.form_1.Fecha_Nacimiento.formatted;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${PACIENTE}`;
            this.http.post(url, this.form_1, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_antecedentes() {
        this._mensajeSrv.cargando();
        this.form_2.Paciente_Id = this.paciente.Id;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${ANTECEDENTES}`;
            this.http.post(url, this.form_2, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_exploracion() {
        this._mensajeSrv.cargando();
        this.form_7.Historia_Clinica_Id = 1;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${EXPLORACIONFISICA}`;
            this.http.post(url, this.form_7, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(data => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_padecimiento() {
        this._mensajeSrv.cargando();
        this.form_5.Historia_Clinica_Id = 1;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${ANTECEDENTESNOPATOLOGICOS}`;
            this.http.post(url, this.form_3, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(data => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_nopatologicos() {
        this._mensajeSrv.cargando();
        this.form_5.Historia_Clinica_Id = 1;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${PADECIMIENTOACTUAL}`;
            this.http.post(url, this.form_5, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(data => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_resultados() {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${RESULTADOSANALISIS}`;
            this.http.post(url, this.form_8, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(
                    output => {
                        this._mensajeSrv.cerrar();
                        let data = output;
                        if (data.Resultado) {
                            this._mensajeSrv.correctamente(data.Mensaje);
                        }
                        resolve(data.Id);
                    }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_terapeutica() {
        this._mensajeSrv.cargando();
        this.form_9.Historia_Clinica_Id = 1;

        for (let i = 0; i < this.form_9.Terapias_Alternativas.length; i++) {
            if (this.form_9.Terapias_Alternativas[i].Fecha != null)
                this.form_9.Terapias_Alternativas[i].Fecha = this.form_9.Terapias_Alternativas[i].Fecha.formatted;
        }
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${TERAPEUTICAEMPLEADA}`;
            this.http.post(url, this.form_9, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = output;
                    console.log(data);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_diagnostico() {
        this._mensajeSrv.cargando();
        this.form_10.Historia_Clinica_Id = 1;
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${DIAGNOSTICO}`;
            this.http.post(url, this.form_10, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = output;
                    console.log(data);
                    if (data.Resultado) {
                        this._mensajeSrv.correctamente(data.Mensaje);
                    }
                    resolve(data.Id);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_infoProfesional() {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${INFORMACIONPROFESIONAL}`;
            this.http.post(url, this.form_11, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(
                    output => {
                        this._mensajeSrv.cerrar();
                        let data = output;
                        console.log(data);
                        if (data.Resultado) {
                            this._mensajeSrv.correctamente(data.Mensaje);
                        }
                        resolve(data.Id);
                    }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_interrogatorio_sino() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${INTERROGATORIO}/SiNo`;
            this.http.post(url, this.form_6, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(
                    output => {
                        let data = output;
                        console.log(data);
                        if (data.Resultado) {
                            // this._mensajeSrv.correctamente(data.Mensaje);
                        }
                        resolve(data.Id);
                    }, err => { });
        });
        return promesa;
    }

    guardar_interrogatorio_detalle() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${INTERROGATORIO}/Detalle`;
            this.http.post(url, this.form_6, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(
                    output => {
                        let data = output;
                        console.log(data);
                        if (data.Resultado) {
                            // this._mensajeSrv.correctamente(data.Mensaje);
                        }
                        resolve(data.Id);
                    }, err => { });
        });
        return promesa;
    }

    guardar_interrogatorio_especifique() {
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${INTERROGATORIO}/Especifique`;
            this.http.post(url, this.form_6, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(
                    output => {
                        let data = output;
                        console.log(data);
                        if (data.Resultado) {
                            // this._mensajeSrv.correctamente(data.Mensaje);
                        }
                        resolve(data.Id);
                    }, err => { });
        });
        return promesa;
    }

    upload(file: File) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${FILES}/Upload`;

            const formData: FormData = new FormData();
            formData.append('File', file, file.name);

            this.http.post(url, formData)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe((data: any) => {
                    this._mensajeSrv.cerrar();
                    resolve(data);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    download(formato: Archivo) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${FILES}/Download`;
            this.http.post(url, formato, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(data => {
                    this._mensajeSrv.cerrar();
                    resolve(data);
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }
}