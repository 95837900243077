import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';

@Component({
  selector: 'app-menu-historia-clinica-movil',
  templateUrl: './menu-historia-clinica-movil.component.html',
  styles: []
})
export class MenuHistoriaClinicaMovilComponent implements OnInit {

  constructor(
    public _pacienteSrv: PacienteService,
  ) { }

  ngOnInit() {
  }

}
