import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { Indice } from 'src/app/class/indice-escalas.class';

@Component({
  selector: 'app-form-indice',
  templateUrl: './form-indice.component.html',
  styles: []
})
export class FormIndiceComponent implements OnInit {

  total = 2;
  pagina = 1;
  escalas: Indice[] = [];

  constructor(
    public _catalogoSrv: CatalogoService
  ) {
    this.paginador();
   }

  ngOnInit() {
  }

  paginador() {
    this._catalogoSrv.escalas(this.pagina, 12)
      .then((data: Indice[]) => {
        this.escalas = [];
        this.escalas.push(...data);
        // this.recargar();
      });
  }

  guardar(forma: NgForm) {
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
    this.paginador();
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
    this.paginador();
  }

}
