import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-edb',
  templateUrl: './form-edb.component.html',
  styles: []
})
export class FormEDBComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 5;
  pagina = 1;
  data = [];
  respaldo = [];
  result = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  paginador() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        x.Id = element.Id;
        x.Opcion_0 = element.Opcion_0;
        x.Opcion_1 = element.Opcion_1;
        x.Opcion_2 = element.Opcion_2;
        x.Opcion_3 = element.Opcion_3;
        x.Seleccionado = element.Seleccionado;
      } else {
        this.respaldo.push(element);
      }
    });

    this._catalogoSrv.escalas_detalle('EDB', this.pagina, 6)
      .then((data: any[]) => {
        this.data = [];
        this.data.push(...data);
        this.data.forEach(element => {
          element.Seleccionado = 0;
        });
        this.recargar();
      });

    this._historialSrv.escalas(1, 14, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data);
      });
  }

  recargar() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        element.Id = x.Id;
        element.Opcion_0 = x.Opcion_0;
        element.Opcion_1 = x.Opcion_1;
        element.Opcion_2 = x.Opcion_2;
        element.Opcion_3 = x.Opcion_3;
        element.Seleccionado = x.Seleccionado;
      } else {
        this.respaldo.push(element);
      }
    });
  }

  seleccionar(item: any, i: number) {
    item.Seleccionado = i;
    let x = this.respaldo.find(x => x.Id === item.Id);
    if (x === undefined) {
      this.respaldo.push(item);
    }
    this.suma();
    // this.seleccionado = item;
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this.respaldo.forEach(element => {
      if (element.Seleccionado > 0) {
        this.seleccionado.Puntaje += Number(element.Seleccionado);
      }
    });
  }

  ngOnInit() {
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 14;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
    this.paginador();
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
    this.paginador();
  }

}
