import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';

@Component({
  selector: 'app-form-interrogatorio-aparatos-sistemas',
  templateUrl: './form-interrogatorio-aparatos-sistemas.component.html',
  styles: []
})
export class FormInterrogatorioAparatosSistemasComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  total = 18;
  pagina = 1;

  constructor(
    public paciente: PacienteService
  ) { }

  ngOnInit() {
  }

  handleChange($event: any, Tipo: string, Descripcion: string) {
    this.paciente.form_6.Descripcion = Descripcion;
    this.paciente.form_6.Tipo_Anamenesis_Dsc = Tipo;
    this.paciente.form_6.Historial_Clinico_Id = 1;
    this.paciente.form_6._SiNo = $event.target.value;

    this.paciente.guardar_interrogatorio_sino()
      .then(() => {
        this.paciente.form_6.Descripcion = '';
        this.paciente.form_6.Tipo_Anamenesis_Dsc = '';
      });
  }

  onBlurDetalle($event, Tipo: string, Descripcion: string, Detalle: string) {
    this.paciente.form_6.Descripcion = Descripcion;
    this.paciente.form_6.Tipo_Anamenesis_Dsc = Tipo;
    this.paciente.form_6.Historial_Clinico_Id = 1;
    this.paciente.form_6._Detalle = Detalle;
    this.paciente.form_6._Especifique = $event.formatted;

    this.paciente.guardar_interrogatorio_detalle()
      .then(() => {
        this.paciente.form_6.Descripcion = '';
        this.paciente.form_6.Tipo_Anamenesis_Dsc = '';
      });
  }

  onBlurEspecifique($event, Tipo: string, Descripcion: string, Detalle: string) {
    this.paciente.form_6.Descripcion = Descripcion;
    this.paciente.form_6.Tipo_Anamenesis_Dsc = Tipo;
    this.paciente.form_6.Historial_Clinico_Id = 1;
    this.paciente.form_6._Detalle = Detalle;
    this.paciente.form_6._Especifique = $event.target.value;

    this.paciente.guardar_interrogatorio_detalle()
      .then(() => {
        this.paciente.form_6.Descripcion = '';
        this.paciente.form_6.Tipo_Anamenesis_Dsc = '';
      });
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
