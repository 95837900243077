export class Usuario {
    Id: number;
    PersonaId: number;
    Usuario: string;
    Contrasena: string;

    constructor(
        Id: number,
        PersonaId: number,
        Usuario: string,
        Contrasena: string
    ) {
        this.Id = Id;
        this.PersonaId = PersonaId;
        this.Usuario = Usuario;
        this.Contrasena = Contrasena;
    }
}