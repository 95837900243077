import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { TerapiaAlternativa } from 'src/app/class/padecimiento-actual.class';
import { CatalogoService } from 'src/app/services/catalogo.service';


@Component({
  selector: 'app-form-padecimiento-actual',
  templateUrl: './form-padecimiento-actual.component.html',
  styles: []
})
export class FormPadecimientoActualComponent implements OnInit {

  total = 1;
  pagina = 1;

  constructor(
    public _pacienteSrv: PacienteService,
    public _cataligoSrv: CatalogoService
  ) {
    for (let e = 0; e < 10; e++) {
      // _pacienteSrv.form_5.Terapias_Alternativas.push(new TerapiaAlternativa(0, '', ''));
    }
  }

  ngOnInit() {
    this._cataligoSrv.terapiasAlternativas()
      .then((data: any) => {
        this._pacienteSrv.form_5.Terapias_Alternativas = [];
        data.forEach(element => {
          let item = new TerapiaAlternativa(element.Id, element.Descripcion, '');
          item.Seleccionado = false;
          this._pacienteSrv.form_5.Terapias_Alternativas.push(item);
        });
      });
  }

  guardar(forma: NgForm) {
    this._pacienteSrv.guardar_padecimiento()
      .then((Id: number) => {
        // this._pacienteSrv.consultar(Id)
        //   .then((data: any[]) => {
        //     if (data.length > 0) {
        //       this._pacienteSrv.paciente = data[0];
        //     }
        //   });
      });
  }
  validar(forma: NgForm): boolean {
    return forma.invalid;
  }
  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }


}
