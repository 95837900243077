import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { modalOptions } from '../../../services/url.servicios';
import { ExpedienteService } from '../../../services/expediente.service';
import { LogoComponent } from '../../logo/logo.component';
import { SignosVitales } from 'src/app/class/nota-evolucion.class';

@Component({
  selector: 'app-form-nota-evolucion',
  templateUrl: './form-nota-evolucion.component.html',
  styles: []
})
export class FormNotaEvolucionComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 2;
  pagina = 1;
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;
  signos: SignosVitales = new SignosVitales(0, 0, '', '', '', '', '', '', '', '');

  signosVitalesData = [];
  diagnosticosData = [];
  logo: string;

  constructor(
    public _expedienteSrv: ExpedienteService,
    public _catalogoSrv: CatalogoService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this._expedienteSrv.consultar_nota_evolucion(2)
      .then((data: any) => {
        this._expedienteSrv.form_3 = data.Data;
        // data.Data.Signos_Vitales = [data.Data.Signos_Vitales];
        if (data.Data.Signos_Vitales) {
          this.signosVitalesData = [];
          this.signosVitalesData.push(...data.Data.Signos_Vitales);
        }

        // data.Data.Diagnosticos__Actuales = [data.Data.Diagnosticos_Actuales];
        this.diagnosticosData = [];
        this.diagnosticosData.push(...data.Data.Diagnosticos_Actuales);

        this.logo = data.Data.Logo;
      });
  }

  guardar(forma?: NgForm) {
    if (this.signos.Estado_Fisico !== '') {
      if (this._expedienteSrv.form_3.Signos_Vitales) {
        this._expedienteSrv.form_3.Signos_Vitales.push(this.signos);
      } else {
        this._expedienteSrv.form_3.Signos_Vitales = [this.signos];
      }
    }

    this._expedienteSrv.guardar_nota_evolucion()
      .then(() => {
        this.signos = new SignosVitales(0, 0, '', '', '', '', '', '', '', '');
        this.ngOnInit();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  verArchivos(titulo: string) {
    this.dialogService.addDialog(LogoComponent, {
      title: `${titulo}`,
      message: '',
      data: { titulo }
    }, modalOptions)
      .subscribe((isConfirmed: any) => {
        this.ngOnInit();
      });
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
