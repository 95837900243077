import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';

import { DataTableModule } from 'angular2-datatable';
import { MyDatePickerModule } from 'mydatepicker';

import { AdminAuthGuardService } from './services/admin.auth-guard.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { IndexHistorialClinicoComponent } from './components/historial/index-historial-clinico/index-historial-clinico.component';
import { PanelHistorialClinicoComponent } from './components/historial/panel-historial-clinico/panel-historial-clinico.component';
import { IndexPacientesComponent } from './components/historial/index-pacientes/index-pacientes.component';
import { UsuarioService } from './services/usuario.service';
import { MensajeService } from './services/mensajes.service';
import { IndexHistoriaClinicaComponent } from './components/historial/index-historia-clinica/index-historia-clinica.component';
import { FormFichaIdentificacionComponent } from './components/historial/form-ficha-identificacion/form-ficha-identificacion.component';
import { FormAntecedentesHeredoFamiliaresComponent } from './components/historial/form-antecedentes-heredo-familiares/form-antecedentes-heredo-familiares.component';
import { FormAntecedPersNoPatologicosComponent } from './components/historial/form-anteced-pers-no-patologicos/form-anteced-pers-no-patologicos.component';
import { FormAntecedPersPatologicosComponent } from './components/historial/form-anteced-pers-patologicos/form-anteced-pers-patologicos.component';
import { FormPadecimientoActualComponent } from './components/historial/form-padecimiento-actual/form-padecimiento-actual.component';
import { FormInterrogatorioAparatosSistemasComponent } from './components/historial/form-interrogatorio-aparatos-sistemas/form-interrogatorio-aparatos-sistemas.component';
import { HistorialService } from './services/historial.service';
import { MenuHistoriaClinicaComponent } from './components/historial/menu-historia-clinica/menu-historia-clinica.component';
import { GridPacientesComponent } from './components/paciente/grid-pacientes/grid-pacientes.component';
import { PacienteService } from './services/paciente.service';
import { CatalogoService } from './services/catalogo.service';
import { FormExploracionFisicaComponent } from './components/historial/form-exploracion-fisica/form-exploracion-fisica.component';
import { FormResultadosAnalisisComponent } from './components/historial/form-resultados-analisis/form-resultados-analisis.component';
import { FormTerapeuticaEmpleadaComponent } from './components/historial/form-terapeutica-empleada/form-terapeutica-empleada.component';
import { FormDiagnosticoComponent } from './components/historial/form-diagnostico/form-diagnostico.component';
import { FormInfoProfesionalComponent } from './components/historial/form-info-profesional/form-info-profesional.component';
import { MenuHistoriaClinicaMovilComponent } from './components/historial/menu-historia-clinica-movil/menu-historia-clinica-movil.component';
import { FormIndiceComponent } from './components/escalas/form-indice/form-indice.component';
import { FormEPDLComponent } from './components/escalas/form-epdl/form-epdl.component';
import { FormESCCZComponent } from './components/escalas/form-esccz/form-esccz.component';
import { FormEVAComponent } from './components/escalas/form-eva/form-eva.component';
import { FormEMMComponent } from './components/escalas/form-emm/form-emm.component';
import { FormEAComponent } from './components/escalas/form-ea/form-ea.component';
import { FormEDComponent } from './components/escalas/form-ed/form-ed.component';
import { FormENComponent } from './components/escalas/form-en/form-en.component';
import { FormEDFComponent } from './components/escalas/form-edf/form-edf.component';
import { FormESGBComponent } from './components/escalas/form-esgb/form-esgb.component';
import { FormEEDMONTComponent } from './components/escalas/form-eedmont/form-eedmont.component';
import { FormEKComponent } from './components/escalas/form-ek/form-ek.component';
import { FormEECOGComponent } from './components/escalas/form-eecog/form-eecog.component';
import { FormEMComponent } from './components/escalas/form-em/form-em.component';
import { FormEDBComponent } from './components/escalas/form-edb/form-edb.component';
import { FormEEPComponent } from './components/escalas/form-eep/form-eep.component';
import { FormEAAMTComponent } from './components/escalas/form-eaamt/form-eaamt.component';
import { FormEDAMComponent } from './components/escalas/form-edam/form-edam.component';
import { FormEMMCLComponent } from './components/escalas/form-emmcl/form-emmcl.component';
import { FormEAPNComponent } from './components/escalas/form-eapn/form-eapn.component';
import { FormEMDCPComponent } from './components/escalas/form-emdcp/form-emdcp.component';
import { MenuEscalasComponent } from './components/escalas/menu-escalas/menu-escalas.component';
import { IndexEscalasComponent } from './components/escalas/index-escalas/index-escalas.component';
import { IndexExpedienteClinicoComponent } from './components/expediente/index-expediente-clinico/index-expediente-clinico.component';
import { MenuExpedienteClinicoComponent } from './components/expediente/menu-expediente-clinico/menu-expediente-clinico.component';
import { MenuExpedienteClinicoMovilComponent } from './components/expediente/menu-expediente-clinico-movil/menu-expediente-clinico-movil.component';
import { FormHojaFrontalComponent } from './components/expediente/form-hoja-frontal/form-hoja-frontal.component';
import { FormFichaClinicaComponent } from './components/expediente/form-ficha-clinica/form-ficha-clinica.component';
import { FormNotaEvolucionComponent } from './components/expediente/form-nota-evolucion/form-nota-evolucion.component';
import { FormHojaIndicacionesComponent } from './components/expediente/form-hoja-indicaciones/form-hoja-indicaciones.component';
import { FormPostOperatoriaComponent } from './components/expediente/form-post-operatoria/form-post-operatoria.component';
import { FormConsentimientoComponent } from './components/expediente/form-consentimiento/form-consentimiento.component';
import { FormNotaEnfermeriaComponent } from './components/expediente/form-nota-enfermeria/form-nota-enfermeria.component';
import { FormValoracionEnfermeriaComponent } from './components/expediente/form-valoracion-enfermeria/form-valoracion-enfermeria.component';
import { FormNotaEgresoComponent } from './components/expediente/form-nota-egreso/form-nota-egreso.component';
import { FormAvisoPrivacidadComponent } from './components/expediente/form-aviso-privacidad/form-aviso-privacidad.component';
import { FormEstudiosComponent } from './components/expediente/form-estudios/form-estudios.component';
import { FormNotaReferenciaComponent } from './components/expediente/form-nota-referencia/form-nota-referencia.component';
import { FormEgresoVoluntarioComponent } from './components/expediente/form-egreso-voluntario/form-egreso-voluntario.component';
import { FormRecuersoComponent } from './components/expediente/form-recuerso/form-recuerso.component';
import { FormAgregarComponent } from './components/expediente/form-agregar/form-agregar.component';
import { IndexEstudioSocioeconomicoComponent } from './components/socioeconomico/index-estudio-socioeconomico/index-estudio-socioeconomico.component';
import { MenuEstudioSocioeconomicoComponent } from './components/socioeconomico/menu-estudio-socioeconomico/menu-estudio-socioeconomico.component';
import { MenuEstudioSocioeconomicoMovilComponent } from './components/socioeconomico/menu-estudio-socioeconomico-movil/menu-estudio-socioeconomico-movil.component';
import { FormESEComponent } from './components/socioeconomico/form-ese/form-ese.component';
import { FormFamiliogramaComponent } from './components/socioeconomico/form-familiograma/form-familiograma.component';
import { FormFIPComponent } from './components/socioeconomico/form-fip/form-fip.component';
import { FormFATComponent } from './components/socioeconomico/form-fat/form-fat.component';
import { FormFIPLComponent } from './components/socioeconomico/form-fipl/form-fipl.component';
import { FormArchivoComponent } from './components/form-archivo/form-archivo.component';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { ExpedienteService } from './services/expediente.service';
import { LogoComponent } from './components/logo/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IndexHistorialClinicoComponent,
    PanelHistorialClinicoComponent,
    IndexPacientesComponent,
    IndexHistoriaClinicaComponent,
    FormFichaIdentificacionComponent,
    FormAntecedentesHeredoFamiliaresComponent,
    FormAntecedPersNoPatologicosComponent,
    FormAntecedPersPatologicosComponent,
    FormPadecimientoActualComponent,
    FormInterrogatorioAparatosSistemasComponent,
    MenuHistoriaClinicaComponent,
    GridPacientesComponent,
    FormExploracionFisicaComponent,
    FormResultadosAnalisisComponent,
    FormTerapeuticaEmpleadaComponent,
    FormDiagnosticoComponent,
    FormInfoProfesionalComponent,
    MenuHistoriaClinicaMovilComponent,
    FormIndiceComponent,
    FormEPDLComponent,
    FormESCCZComponent,
    FormEVAComponent,
    FormEMMComponent,
    FormEAComponent,
    FormEDComponent,
    FormENComponent,
    FormEDFComponent,
    FormESGBComponent,
    FormEEDMONTComponent,
    FormEKComponent,
    FormEECOGComponent,
    FormEMComponent,
    FormEDBComponent,
    FormEEPComponent,
    FormEAAMTComponent,
    FormEDAMComponent,
    FormEMMCLComponent,
    FormEAPNComponent,
    FormEMDCPComponent,
    MenuEscalasComponent,
    IndexEscalasComponent,
    IndexExpedienteClinicoComponent,
    MenuExpedienteClinicoComponent,
    MenuExpedienteClinicoMovilComponent,
    FormHojaFrontalComponent,
    FormFichaClinicaComponent,
    FormNotaEvolucionComponent,
    FormHojaIndicacionesComponent,
    FormPostOperatoriaComponent,
    FormConsentimientoComponent,
    FormNotaEnfermeriaComponent,
    FormValoracionEnfermeriaComponent,
    FormNotaEgresoComponent,
    FormAvisoPrivacidadComponent,
    FormEstudiosComponent,
    FormNotaReferenciaComponent,
    FormEgresoVoluntarioComponent,
    FormRecuersoComponent,
    FormAgregarComponent,
    IndexEstudioSocioeconomicoComponent,
    MenuEstudioSocioeconomicoComponent,
    MenuEstudioSocioeconomicoMovilComponent,
    FormESEComponent,
    FormFamiliogramaComponent,
    FormFIPComponent,
    FormFATComponent,
    FormFIPLComponent,
    FormArchivoComponent,
    LogoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    DataTableModule,
    MyDatePickerModule
  ],
  providers: [
    AdminAuthGuardService,
    UsuarioService,
    MensajeService,
    HistorialService,
    PacienteService,
    CatalogoService,
    ExpedienteService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FormArchivoComponent,
    LogoComponent
  ]
})
export class AppModule { }
