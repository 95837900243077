export class PadecimientoActual {
    Historia_Clinica_Id: number;
    Padecimiento_Actual: string;
    Terapias_Alternativas: TerapiaAlternativa[];

    constructor(
        Padecimiento_Actual: string,
        Terapias_Alternativas: TerapiaAlternativa[]
    ) {
        this.Padecimiento_Actual = Padecimiento_Actual;
        this.Terapias_Alternativas = Terapias_Alternativas;
    }
}

export class TerapiaAlternativa {
    Terapia_Alternativa_Id: number;
    Terapia_Alternativa_Dsc: string;
    Especifique: string;
    Seleccionado: boolean;

    constructor(
        Terapia_Alternativa_Id: number,
        Terapia_Alternativa_Dsc: string,
        Especifique: string
    ) {
        this.Terapia_Alternativa_Id = Terapia_Alternativa_Id;
        this.Terapia_Alternativa_Dsc = Terapia_Alternativa_Dsc;
        this.Especifique = Especifique;
    }
}