
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVICIOS, OPTIONS, USUARIO } from '../services/url.servicios';
import { map, timeoutWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MensajeService } from './mensajes.service';
import { Router } from '@angular/router';
import { Usuario } from '../class/usuario.class';

@Injectable()
export class UsuarioService {

    usuario: Usuario = new Usuario(0, 0, '', '');
    private Autenticado = false;

    constructor(
        public http: Http,
        public _mensajeSrv: MensajeService,
        public router: Router,
    ) {

    }

    isAdminAuthenticated() {
        if (localStorage.getItem('adp.login.auth.currentUser.uid') !== 'null' && localStorage.getItem('adp.login.auth.currentUser.uid') !== null) {
            this.usuario = JSON.parse(localStorage.getItem('adp.login.auth.currentUser.uid'));
            this.Autenticado = this.usuario.Id !== 0 && this.usuario.Usuario === 'admin';
        }
        return this.Autenticado;
    }

    isAuthenticated() {
        if (localStorage.getItem('adp.login.auth.currentUser.uid') !== 'null' && localStorage.getItem('adp.login.auth.currentUser.uid') !== null) {
            this.usuario = JSON.parse(localStorage.getItem('adp.login.auth.currentUser.uid'));
            this.Autenticado = this.usuario.Id !== 0;
        }
        return this.Autenticado;
    }

    consultar(Usuario: string, Contrasena: string) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${USUARIO}`;

            this.http.post(url, { Usuario: Usuario, Contrasena: Contrasena }, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    // timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (data.Data.Id === 0) {
                            this._mensajeSrv.mensaje('El usuario y/o la contraseña son incorrectos');
                        } else {
                            this.usuario = data.Data;
                            this.Autenticado = true;
                            localStorage.setItem('adp.login.auth.currentUser.uid', JSON.stringify(this.usuario));
                        }
                    }
                    resolve(data.Data.Id > 0);
                }, err => {
                    this._mensajeSrv.cerrar();
                    this._mensajeSrv.error();
                });
        });
        return promesa;
    }

    cerrar() {
        this.Autenticado = false;
        localStorage.setItem('login.auth.currentUser.uid', null);
        this.router.navigate(['/home']);
    }
}