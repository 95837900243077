export class TerapeuticaEmpleada {
    Historia_Clinica_Id: number;
    Medicamentos: string;
    Via: string;
    Dosis: string;
    Periocidad: string;
    Terapias_Alternativas: TerapiasAlternativas[];

    constructor(
        Medicamentos: string,
        Via: string,
        Dosis: string,
        Periocidad: string,
        Terapias_Alternativas: TerapiasAlternativas[]
    ) {

        this.Medicamentos = Medicamentos;
        this.Via = Via;
        this.Dosis = Dosis;
        this.Periocidad = Periocidad;
        this.Terapias_Alternativas = Terapias_Alternativas;
    }

}

export class TerapiasAlternativas {
    Terapia_Alternativa_Id: number;
    Fecha: any;
    Resultado: string;
    Terapeura: string;

    constructor(
        Terapia_Alternativa_Id: number,
        Fecha: any,
        Resultado: string,
        Terapeura: string
    ) {
        this.Terapia_Alternativa_Id = Terapia_Alternativa_Id;
        this.Fecha = Fecha;
        this.Resultado = Resultado;
        this.Terapeura = Terapeura;
    }
}