export class AntecedentesNoPatologicos {
    // Page 1
    Alimentacion: string;
    Pecho_Materno: boolean;
    Duracion_Pecho: number;
    Duracion_Ablactacion: number;
    Gesta: string;
    Para: string;
    Cesarea: string;
    Abortos: string;
    Edad_Embarazo: string;
    Semanas_Gestacion: string;
    Complicaciones_Embarazo: string;
    Traumatismos_Embarazo: string;

    // Page 2
    Caracteristicas_Parto: string;
    Duracion_Parto: string;
    //Semanas_Gestacion: string; Es el mismo de Page 1
    Donde_Atendida: string;
    Complicaciones_Extraccion: string;
    Forceps: number;
    Caracteristicas_Liquido: string;
    Respiro_Lloro: number;
    Metodo_Aprendizaje_1: string;
    Metodo_Aprendizaje_2: string;
    Oxigeno: number;
    Ventilacion: number;
    Intubacion: number;
    Medicamentos: number;
    Incubadora: string;

    // Page 3
    Desarrollo_Psicomotor: DesarrolloPsicomotor[];
    Inicio_Denticion: number;
    Anodoncia_Parcial: string;
    Piezas_Dentarias: string;
    Caries_Previamente: number;
    Aparato_Ortodoncia: number;
    Seno_Materno: string;
    Inicio_Ablactacion: string;
    Intregracion_Dieta: string;
    Inmunizaciones: string;
    Habitat: string;

    // Page 4
    Tipo_Vivienda_Id: number;
    Tipo_Vivienda_Dsc: string;
    Servicios_Intra_Domiciliarios: ServiciosDomiciliarios[];
    Servicios_Extra_Domiciliarios: ServiciosDomiciliarios[];
    Habitacion: string;
    Eliminacion_Excretas: string;
    Fecalismo: number;
    Letrina: number;
    Tipo_Ingles: number;
    Agua_Intradomiciliaria: boolean;

    // Page 5
    Banio_Familiar: number;
    Comunal: number;
    Convivencia_Animales: boolean;
    Convivencia_Animales_Cual: string;
    Piso_Casa: boolean;
    Cemento_Recubrimiento: boolean;
    Cuartos_Casa: number;
    Personas_Casa: number;
    Refrigerador: boolean;
    Telefono: boolean;
    Automovil: boolean;
    Exposicion_Sustancias: boolean;
    Exposicion_Sustancias_Cuales: string;
    Horas_Sueño: number;
    Ciclo_Circadiano: boolean;

    // Page 6
    Frecuenta_Alimentacion: Alimentacion[];
    Adecuada: string;

    // Page 7
    Recreacion_Familiar: string;
    Actividades_Familiares: ActividadesFamiliares[];
    Cartilla_Vacunacion: boolean;
    Cartilla_Vacunacion_Presenta: number;
    Cartilla_Inmunizaciones: Inmunizacion[];
    Vacunas_Adicionales: string;

    // Page 8
    Alcohol: boolean;
    Alcohol_Frecuencia: string;
    Alcohol_Cuando: any;
    Tabaco: boolean;
    Tabaco_Frecuencia: string;
    Tabaco_Cuando: any;
    Indice_Tabaquico: string;
    Bucofaringeas: boolean;
    Bucofaringeas_Cuando: any;
    Zoonosis: boolean;
    Zoonosis_Cuando: any;
    Banio_Frecuencia: string;
    Cuidado_Banio: boolean;
    Cuidado_Banio_Cuando: any;
    Cada_Cuando: string;
    Lavado_Dientes: boolean;
    Lavado_Dientes_Frecuencia: any;
    Cuidado_Cavidad: boolean;
    Cuidado_Cavidad_Especificar: any;

    constructor(
        Alimentacion: string,
        Pecho_Materno: boolean,
        Duracion_Pecho: number,
        Duracion_Ablactacion: number,
        Gesta: string, Para: string,
        Cesarea: string, Abortos: string,
        Edad_Embarazo: string,
        Semanas_Gestacion: string,
        Complicaciones_Embarazo: string,
        Traumatismos_Embarazo: string,
        Caracteristicas_Parto: string,
        Duracion_Parto: string,
        Donde_Atendida: string,
        Complicaciones_Extraccion: string,
        Forceps: number,
        Caracteristicas_Liquido: string,
        Respiro_Lloro: number,
        Metodo_Aprendizaje_1: string,
        Metodo_Aprendizaje_2: string,
        Oxigeno: number, Ventilacion: number,
        Intubacion: number, Medicamentos: number,
        Incubadora: string,
        Desarrollo_Psicomotor: DesarrolloPsicomotor[],
        Inicio_Denticion: number,
        Anodoncia_Parcial: string,
        Piezas_Dentarias: string,
        Caries_Previamente: number,
        Aparato_Ortodoncia: number,
        Seno_Materno: string,
        Inicio_Ablactacion: string,
        Intregracion_Dieta: string,
        Inmunizaciones: string,
        Habitat: string,
        Tipo_Vivienda_Id: number,
        Tipo_Vivienda_Dsc: string,
        Servicios_Intra_Domiciliarios: ServiciosDomiciliarios[],
        Servicios_Extra_Domiciliarios: ServiciosDomiciliarios[],
        Habitacion: string,
        Eliminacion_Excretas: string,
        Fecalismo: number,
        Letrina: number,
        Tipo_Ingles: number,
        Agua_Intradomiciliaria: boolean,
        Banio_Familiar: number,
        Comunal: number,
        Convivencia_Animales: boolean,
        Convivencia_Animales_Cual: string,
        Piso_Casa: boolean,
        Cemento_Recubrimiento: boolean,
        Cuartos_Casa: number,
        Personas_Casa: number,
        Refrigerador: boolean,
        Telefono: boolean,
        Automovil: boolean,
        Exposicion_Sustancias: boolean,
        Exposicion_Sustancias_Cuales: string,
        Ciclo_Circadiano: boolean,
        Frecuenta_Alimentacion: Alimentacion[],
        Adecuada: string,
        Recreacion_Familiar: string,
        Actividades_Familiares: ActividadesFamiliares[],
        Cartilla_Vacunacion: boolean,
        Cartilla_Vacunacion_Presenta: number,
        Cartilla_Inmunizaciones: Inmunizacion[],
        Vacunas_Adicionales: string,
        Alcohol: boolean,
        Alcohol_Frecuencia: string,
        Alcohol_Cuando: any,
        Tabaco: boolean,
        Tabaco_Frecuencia: string,
        Tabaco_Cuando: any,
        Indice_Tabaquico: string,
        Bucofaringeas: boolean,
        Bucofaringeas_Cuando: any,
        Zoonosis: boolean,
        Zoonosis_Cuando: any,
        Banio_Frecuencia: string,
        Cuidado_Banio: boolean,
        Cuidado_Banio_Cuando: any,
        Cada_Cuando: string,
        Lavado_Dientes: boolean,
        Lavado_Dientes_Frecuencia: any,
        Cuidado_Cavidad: boolean,
        Cuidado_Cavidad_Especificar: any) {
        this.Alimentacion = Alimentacion;
        this.Pecho_Materno = Pecho_Materno;
        this.Duracion_Pecho = Duracion_Pecho;
        this.Duracion_Ablactacion = Duracion_Ablactacion;
        this.Gesta = Gesta;
        this.Para = Para;
        this.Cesarea = Cesarea;
        this.Abortos = Abortos;
        this.Edad_Embarazo = Edad_Embarazo;
        this.Semanas_Gestacion = Semanas_Gestacion;
        this.Complicaciones_Embarazo = Complicaciones_Embarazo;
        this.Traumatismos_Embarazo = Traumatismos_Embarazo;
        this.Caracteristicas_Parto = Caracteristicas_Parto;
        this.Duracion_Parto = Duracion_Parto;
        this.Semanas_Gestacion = Semanas_Gestacion;
        this.Donde_Atendida = Donde_Atendida;
        this.Complicaciones_Extraccion = Complicaciones_Extraccion;
        this.Forceps = Forceps;
        this.Caracteristicas_Liquido = Caracteristicas_Liquido;
        this.Respiro_Lloro = Respiro_Lloro;
        this.Metodo_Aprendizaje_1 = Metodo_Aprendizaje_1;
        this.Metodo_Aprendizaje_2 = Metodo_Aprendizaje_2;
        this.Oxigeno = Oxigeno;
        this.Ventilacion = Ventilacion;
        this.Intubacion = Intubacion;
        this.Medicamentos = Medicamentos;
        this.Incubadora = Incubadora;
        this.Desarrollo_Psicomotor = Desarrollo_Psicomotor;
        this.Inicio_Denticion = Inicio_Denticion;
        this.Anodoncia_Parcial = Anodoncia_Parcial;
        this.Piezas_Dentarias = Piezas_Dentarias;
        this.Caries_Previamente = Caries_Previamente;
        this.Aparato_Ortodoncia = Aparato_Ortodoncia;
        this.Seno_Materno = Seno_Materno;
        this.Inicio_Ablactacion = Inicio_Ablactacion;
        this.Intregracion_Dieta = Intregracion_Dieta;
        this.Inmunizaciones = Inmunizaciones;
        this.Habitat = Habitat;
        this.Tipo_Vivienda_Id = Tipo_Vivienda_Id;
        this.Tipo_Vivienda_Dsc = Tipo_Vivienda_Dsc;
        this.Servicios_Intra_Domiciliarios = Servicios_Intra_Domiciliarios;
        this.Servicios_Extra_Domiciliarios = Servicios_Extra_Domiciliarios;
        this.Habitacion = Habitacion;
        this.Eliminacion_Excretas = Eliminacion_Excretas;
        this.Fecalismo = Fecalismo;
        this.Letrina = Letrina;
        this.Tipo_Ingles = Tipo_Ingles;
        this.Agua_Intradomiciliaria = Agua_Intradomiciliaria;
        this.Banio_Familiar = Banio_Familiar;
        this.Comunal = Comunal;
        this.Convivencia_Animales = Convivencia_Animales;
        this.Convivencia_Animales_Cual = Convivencia_Animales_Cual;
        this.Piso_Casa = Piso_Casa;
        this.Cemento_Recubrimiento = Cemento_Recubrimiento;
        this.Cuartos_Casa = Cuartos_Casa;
        this.Personas_Casa = Personas_Casa;
        this.Refrigerador = Refrigerador;
        this.Telefono = Telefono;
        this.Automovil = Automovil;
        this.Exposicion_Sustancias = Exposicion_Sustancias;
        this.Exposicion_Sustancias_Cuales = Exposicion_Sustancias_Cuales;
        this.Ciclo_Circadiano = Ciclo_Circadiano;
        this.Frecuenta_Alimentacion = Frecuenta_Alimentacion;
        this.Adecuada = Adecuada;
        this.Recreacion_Familiar = Recreacion_Familiar;
        this.Actividades_Familiares = Actividades_Familiares;
        this.Cartilla_Vacunacion = Cartilla_Vacunacion;
        this.Cartilla_Vacunacion_Presenta = Cartilla_Vacunacion_Presenta;
        this.Cartilla_Inmunizaciones = Cartilla_Inmunizaciones;
        this.Vacunas_Adicionales = Vacunas_Adicionales;
        this.Alcohol = Alcohol;
        this.Alcohol_Frecuencia = Alcohol_Frecuencia;
        this.Alcohol_Cuando = Alcohol_Cuando;
        this.Tabaco = Tabaco;
        this.Tabaco_Frecuencia = Tabaco_Frecuencia;
        this.Tabaco_Cuando = Tabaco_Cuando;
        this.Indice_Tabaquico = Indice_Tabaquico;
        this.Bucofaringeas = Bucofaringeas;
        this.Bucofaringeas_Cuando = Bucofaringeas_Cuando;
        this.Zoonosis = Zoonosis;
        this.Zoonosis_Cuando = Zoonosis_Cuando;
        this.Banio_Frecuencia = Banio_Frecuencia;
        this.Cuidado_Banio = Cuidado_Banio;
        this.Cuidado_Banio_Cuando = Cuidado_Banio_Cuando;
        this.Cada_Cuando = Cada_Cuando;
        this.Lavado_Dientes = Lavado_Dientes;
        this.Lavado_Dientes_Frecuencia = Lavado_Dientes_Frecuencia;
        this.Cuidado_Cavidad = Cuidado_Cavidad;
        this.Cuidado_Cavidad_Especificar = Cuidado_Cavidad_Especificar;
    }

}

export class DesarrolloPsicomotor {
    Desarrollo_Psicomotor_Id: number;
    Desarrollo_Psicomotor_Dsc: string;
    Meses: number;

    constructor(
        Desarrollo_Psicomotor_Id: number,
        Desarrollo_Psicomotor_Dsc: string,
        Meses: number
    ) {
        this.Desarrollo_Psicomotor_Id = Desarrollo_Psicomotor_Id;
        this.Desarrollo_Psicomotor_Dsc = Desarrollo_Psicomotor_Dsc;
        this.Meses = Meses;
    }
}

export class ServiciosDomiciliarios {
    Tipo_Servicio_Id: number;
    Tipo_Servicio_Dsc: string;
    Si_No: boolean;
    Especifique: string;

    constructor(
        Tipo_Servicio_Id: number,
        Tipo_Servicio_Dsc: string,
        Si_No: boolean,
        Especifique: string
    ) {
        this.Tipo_Servicio_Id = Tipo_Servicio_Id;
        this.Tipo_Servicio_Dsc = Tipo_Servicio_Dsc;
        this.Si_No = Si_No;
        this.Especifique = Especifique;
    }
}

export class Alimentacion {
    Tipo_Alimento_Id: number;
    Tipo_Alimento_Dsc: string;
    Frecuencia_Id: number;

    constructor(
        Tipo_Alimento_Id: number,
        Tipo_Alimento_Dsc: string,
        Frecuencia_Id: number
    ) {
        this.Tipo_Alimento_Id = Tipo_Alimento_Id;
        this.Tipo_Alimento_Dsc = Tipo_Alimento_Dsc;
        this.Frecuencia_Id = Frecuencia_Id;
    }
}

export class ActividadesFamiliares {
    Actividad_Recreativa_Id: number;
    Actividad_Recreativa_Dsc: string;

    constructor(
        Actividad_Recreativa_Id: number,
        Actividad_Recreativa_Dsc: string
    ) {
        this.Actividad_Recreativa_Id = Actividad_Recreativa_Id;
        this.Actividad_Recreativa_Dsc = Actividad_Recreativa_Dsc;
    }
}

export class Inmunizacion {
    Configuracion_Cartilla_Id: number;
    Fecha_Aplicacion: any;

    constructor(
        Configuracion_Cartilla_Id: number,
        Fecha_Aplicacion: any
    ) {
        this.Configuracion_Cartilla_Id = Configuracion_Cartilla_Id;
        this.Fecha_Aplicacion = Fecha_Aplicacion;
    }
}