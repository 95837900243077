export class Problema {
    Id: number;
    Descripcion: string;
    Familiar: number;
    Especificar: boolean;
    sEspecificar: string;
    Vive: boolean;
    Lugar: string;
    Edad_Fallecimiento: number;

    constructor(
        Id: number,
        Descripcion: string,
        Familiar: number,
        Especificar: boolean,
        sEspecificar: string,
        Vive: boolean,
        Lugar: string,
        Edad_Fallecimiento: number
    ) {
        this.Id = Id;
        this.Descripcion = Descripcion;
        this.Familiar = Familiar;
        this.Especificar = Especificar;
        this.sEspecificar = sEspecificar;
        this.Vive = Vive;
        this.Lugar = Lugar;
        this.Edad_Fallecimiento = Edad_Fallecimiento;
    }
}