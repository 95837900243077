import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { element } from 'protractor';

@Component({
  selector: 'app-form-anteced-pers-no-patologicos',
  templateUrl: './form-anteced-pers-no-patologicos.component.html',
  styles: []
})
export class FormAntecedPersNoPatologicosComponent implements OnInit {

  total = 8;
  pagina = 1;
  tipo_alimentos = [];
  desarrolloPsicomotor = [];
  tipos_servicios = [];

  // tslint:disable-next-line: variable-name
  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogosSrv: CatalogoService
  ) {
  }

  ngOnInit() {
    this._catalogosSrv.tipo_alimentos()
      .then((data: []) => {
        this.tipo_alimentos = [];
        this.tipo_alimentos.push(...data);
        this.tipo_alimentos.forEach(element => {
          element.Frecuencia = 0;
        });
      });

    this._catalogosSrv.desarrolloPsicomotor()
      .then((data: [] ) => {
        this.desarrolloPsicomotor = [];
        this.desarrolloPsicomotor.push(...data);
        this.desarrolloPsicomotor.forEach(element => {
          element.Frecuencia = "";
        });
      });

    this._catalogosSrv.tipos_servicios()
      .then((data: [] ) => {
        this.tipos_servicios = [];
        this.tipos_servicios.push(...data);
        this.tipos_servicios.forEach(element => {
          element.Especifique = "";
          element.Frecuencia = true;
        });
      });

    }

  guardar_nopatologicos(forma: NgForm) {
    this._pacienteSrv.guardar()
      .then();
  }

  validar(forma: NgForm): boolean {
    console.log(forma);
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
