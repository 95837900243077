import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-eapn',
  templateUrl: './form-eapn.component.html',
  styles: []
})
export class FormEAPNComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 5;
  pagina = 1;
  data = [];
  data_1 = [];
  respaldo = [];
  result = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        x.Id = element.Id;
        x.Descripcion = element.Descripcion;
        x.Puntaje = element.Puntaje;
      } else {
        this.respaldo.push(element);
      }
    });

    this._catalogoSrv.escalas_detalle('EAPN', this.pagina, 11)
      .then((data: any[]) => {
        this.data = [];
        this.data.push(...data);
        this.data.forEach(element => {
          element.Puntaje = 1;
        });
        this.recargar();
        this.suma();
      });

    this._historialSrv.escalas(1, 20, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data);
      });
  }

  recargar() {
    this.data.forEach(element => {
      let x = this.respaldo.find(x => x.Id === element.Id);
      if (x) {
        element.Id = x.Id;
        element.Descripcion = x.Descripcion;
        element.Puntaje = x.Puntaje;
      } else {
        this.respaldo.push(element);
      }
    });
  }

  seleccionar(item: any) {
    let x = this.respaldo.find(x => x.Id === item.Id);
    if (x === undefined) {
      this.respaldo.push(item);
    } else {
      this.respaldo.forEach(element => {
        if(element.Id === item.Id){
          element.Puntaje = Number(item.Puntaje);
        }
      });
    }
    
    this.suma();
    // this.seleccionado = item;
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this.respaldo.forEach(element => {
      this.seleccionado.Puntaje += Number(element.Puntaje);
    });
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 20;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.respaldo = [];
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
    this.paginador();
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
    this.paginador();
  }

}
