import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-eedmont',
  templateUrl: './form-eedmont.component.html',
  styles: []
})
export class FormEEDMONTComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 2;
  pagina = 1;
  data = [];
  data_1 = [];
  result = [];
  seleccionado: { Cat_Escalas_Id: number, Fecha: any, Puntaje: number, Observaciones: string } = { Cat_Escalas_Id: 0, Fecha: undefined, Puntaje: 0, Observaciones: '' };

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this._catalogoSrv.escalas_detalle('EEDMONT', 1, 10)
      .then((data: any[]) => {
        this.data = [];
        this.data.push(...data);
        this.data.forEach(element => {
          element.Puntaje = [
            { Value: 1, Seleccionado: true },
            { Value: 2, Seleccionado: false },
            { Value: 3, Seleccionado: false },
            { Value: 4, Seleccionado: false },
            { Value: 5, Seleccionado: false },
            { Value: 6, Seleccionado: false },
            { Value: 7, Seleccionado: false },
            { Value: 8, Seleccionado: false },
            { Value: 9, Seleccionado: false },
            { Value: 10, Seleccionado: false }
          ];
        });
        this.suma();
      });

    this._historialSrv.escalas(1, 10, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data); console.log(this.result);
      });
  }

  seleccionar(item: any, i: number, j: number) {
    item.Puntaje.forEach(element => {
      element.Seleccionado = false;
    });
    item.Puntaje[j].Seleccionado = !item.Puntaje[j].Seleccionado;
    this.suma();
    // this.seleccionado = item;
  }

  suma() {
    this.seleccionado.Puntaje = 0;
    this.data.forEach(element => {
      element.Puntaje.forEach(item => {
        if (item.Seleccionado) {
          this.seleccionado.Puntaje += Number(item.Value);
        }
      });
    });
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 10;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = { Cat_Escalas_Id: 0, Fecha: null, Puntaje: 0, Observaciones: '' };
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
