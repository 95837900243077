import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-panel-historial-clinico',
  templateUrl: './panel-historial-clinico.component.html',
  styles: []
})
export class PanelHistorialClinicoComponent implements OnInit {

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
  }

  pacientes() {
    this.router.navigate(['historial/pacientes']);
  }

}
