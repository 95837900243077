import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-form-eecog',
  templateUrl: './form-eecog.component.html',
  styles: []
})
export class FormEECOGComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha';
  total = 2;
  pagina = 1;
  data = [];
  data_1 = [];
  result = [];
  seleccionado: any;

  constructor(
    public _catalogoSrv: CatalogoService,
    public _historialSrv: HistorialService
  ) {
    this.paginador();
  }

  ngOnInit() {
  }

  paginador() {
    this._catalogoSrv.escalas_detalle('EECOG', 1, 10)
      .then((data: any[]) => {
        this.data = [];
        this.data.push(...data);
      });

    this._historialSrv.escalas(1, 12, 1, 12)
      .then((data: any[]) => {
        this.result = [];
        this.result.push(...data);
      });
  }

  seleccionar(item: any) {
    this.data.forEach(element => {
      element.Seleccionado = false;
    });
    item.Seleccionado = !item.Seleccionado;
    // this.suma();
    this.seleccionado = item;
    this.seleccionado.Puntaje = Number(item.Nivel.replace('ECOG', ''));
  }

  guardar(forma: NgForm) {
    this.seleccionado.Cat_Escalas_Id = 12;
    this.seleccionado.Fecha = this.seleccionado.Fecha.formatted;

    this._historialSrv.insertar_escala(this.seleccionado)
      .then(() => {
        this.seleccionado = undefined;
        this.paginador();
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
