import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { FormArchivoComponent } from '../../form-archivo/form-archivo.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { modalOptions } from 'src/app/services/url.servicios';
import { ResultadosAnalisis } from 'src/app/class/resultados-analisis.class';

@Component({
  selector: 'app-form-resultados-analisis',
  templateUrl: './form-resultados-analisis.component.html',
  styles: []
})
export class FormResultadosAnalisisComponent implements OnInit {

  total = 1;
  pagina = 1;
  archivos: FileList;

  Notas1: string;
  Notas2: string;
  Notas3: string;
  Notas4: string;

  constructor(
    public _pacienteSrv: PacienteService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
  }

  guardar(forma?: NgForm) {
    this._pacienteSrv.form_8 = new ResultadosAnalisis(null, null, null, null, null, []);
    this._pacienteSrv.form_8.Historia_Clinica_Id = 1;
    if (this.Notas1 && this.Notas1.length > 0) {
      this._pacienteSrv.form_8.Notas.push(this.Notas1);
    }
    if (this.Notas2 && this.Notas2.length > 0) {
      this._pacienteSrv.form_8.Notas.push(this.Notas2);
    }
    if (this.Notas3 && this.Notas3.length > 0) {
      this._pacienteSrv.form_8.Notas.push(this.Notas3);
    }
    if (this.Notas4 && this.Notas4.length > 0) {
      this._pacienteSrv.form_8.Notas.push(this.Notas4);
    }
    this._pacienteSrv.guardar_resultados()
      .then(() => {
        // this.Notas1 = '';
        // this.Notas2 = '';
        // this.Notas3 = '';
        // this.Notas4 = '';
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  verArchivos(titulo: string) {
    this.dialogService.addDialog(FormArchivoComponent, {
      title: `${titulo}`,
      message: '',
      data: { titulo }
    }, modalOptions)
      .subscribe((isConfirmed: any) => {
        this.ngOnInit();
      });
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

  mostrar() {
    console.log(this._pacienteSrv.form_8);
    console.log(this.archivos);
  }

}
