export class ResultadosAnalisis {
    Historia_Clinica_Id: number;
    Resultados_Previos: any;
    Resultados_Actuales: any;
    Estudios_Laboratorio: any;
    Estudios_Gabinete: any;
    Otros: any;
    Notas: string[];

    constructor(
        Resultados_Previos: any,
        Resultados_Actuales: any,
        Estudios_Laboratorio: any,
        Estudios_Gabinete: any,
        Otros: any,
        Notas: string[]
    ) {
        this.Resultados_Previos = Resultados_Previos;
        this.Resultados_Actuales = Resultados_Actuales;
        this.Estudios_Laboratorio = Estudios_Laboratorio;
        this.Estudios_Gabinete = Estudios_Gabinete;
        this.Otros = Otros;
        this.Notas = Notas;
    }

}

export class Archivo {
    Id: number;
    Historia_Clinica_Id: number;
    Tipo: string;
    Fecha: string;
    Nombre: string;
    Path: string;

    constructor(
        Id: number,
        Historia_Clinica_Id: number,
        Tipo: string,
        Fecha: string,
        Nombre: string,
        Path: string
    ) {
        this.Id = Id;
        this.Historia_Clinica_Id = Historia_Clinica_Id;
        this.Tipo = Tipo;
        this.Fecha = Fecha;
        this.Nombre = Nombre;
        this.Path = Path;
    }
}