export class InformacionProfesional {
    Fotografia: any;
    Nombre: string;
    Cedula: string;
    Firma: string;

    constructor(
        Fotografia: any,
        Nombre: string,
        Cedula: string,
        Firma: string
    ) {
        this.Fotografia = Fotografia;
        this.Nombre = Nombre;
        this.Cedula = Cedula;
        this.Firma = Firma;
    }

}