import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-estudio-socioeconomico-movil',
  templateUrl: './menu-estudio-socioeconomico-movil.component.html',
  styles: []
})
export class MenuEstudioSocioeconomicoMovilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
