import { DiagnosticoFrontal } from './hoja-frontal.class';

export class NotaEvolucion {
    Expediente_Medico_Id: number;
    Persona_Id: number;
    Fecha: any;
    Hora: string;
    Nombre: string;
    Fecha_Nacimiento: any;
    CURP: string;
    Edad: number;
    Tipo_Sangre: string;
    Signos_Vitales: SignosVitales[];
    Dia_Estancia: string;
    Servicio_Tratante: string;
    Diagnosticos_Actuales: DiagnosticoFrontal[];
    Diagnosticos: string;
    Signos_Sintomas: string;
    Exploracion_Fisica: string;
    Comentarios_Ultimos: string;
    Analisis_Evolucion: string;
    Plan_Estudio: string;
    Tipo_Alimentacion: string;
    Soluciones: string;
    Medicamentos: string;
    Cuidados_Generales: string;
    Interconsultas: string;
    Indicaciones_Otras: string;
    Medico_Tratante: string;
    Responsable_Legal: string;
    Cuidador: string;
    Hospital_Procedencia: string;
    Domicilio: string;
    Telefono: string;
    Logo: string;

    constructor(Expediente_Medico_Id: number, Persona_Id: number, Fecha: any, Hora: string, Nombre: string, Fecha_Nacimiento: any, CURP: string, Edad: number, Tipo_Sangre: string, Signos_Vitales: SignosVitales[], Dia_Estancia: string, Servicio_Tratante: string, Diagnosticos_Actuales: DiagnosticoFrontal[], Signos_Sintomas: string, Exploracion_Fisica: string, Comentarios_Ultimos: string, Analisis_Evolucion: string, Plan_Estudio: string, Tipo_Alimentacion: string, Soluciones: string, Medicamentos: string, Cuidados_Generales: string, Interconsultas: string, Indicaciones_Otras: string, Medico_Tratante: string, Responsable_Legal: string, Cuidador: string, Hospital_Procedencia: string, Domicilio: string, Telefono: string, Logo: string) {
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Persona_Id = Persona_Id;
        this.Fecha = Fecha;
        this.Hora = Hora;
        this.Nombre = Nombre;
        this.Fecha_Nacimiento = Fecha_Nacimiento;
        this.CURP = CURP;
        this.Edad = Edad;
        this.Tipo_Sangre = Tipo_Sangre;
        this.Signos_Vitales = Signos_Vitales;
        this.Dia_Estancia = Dia_Estancia;
        this.Servicio_Tratante = Servicio_Tratante;
        this.Diagnosticos_Actuales = Diagnosticos_Actuales;
        this.Signos_Sintomas = Signos_Sintomas;
        this.Exploracion_Fisica = Exploracion_Fisica;
        this.Comentarios_Ultimos = Comentarios_Ultimos;
        this.Analisis_Evolucion = Analisis_Evolucion;
        this.Plan_Estudio = Plan_Estudio;
        this.Tipo_Alimentacion = Tipo_Alimentacion;
        this.Soluciones = Soluciones;
        this.Medicamentos = Medicamentos;
        this.Cuidados_Generales = Cuidados_Generales;
        this.Interconsultas = Interconsultas;
        this.Indicaciones_Otras = Indicaciones_Otras;
        this.Medico_Tratante = Medico_Tratante;
        this.Responsable_Legal = Responsable_Legal;
        this.Cuidador = Cuidador;
        this.Hospital_Procedencia = Hospital_Procedencia;
        this.Domicilio = Domicilio;
        this.Telefono = Telefono;
        this.Logo = Logo;
    }

}

export class SignosVitales {
    Id: number;
    Historia_Clinica_Id: number;
    Temperatura: string;
    Respiracion: string;
    Pulso: string;
    Tension_Arterial: string;
    Estatura: string;
    Saturacion_Oxigeno: string;
    Fecha_Hora: any;
    Estado_Fisico: string;

    constructor(Id: number, Historia_Clinica_Id: number, Temperatura: string, Respiracion: string, Pulso: string, Tension_Arterial: string, Saturacion_Oxigeno: string, Fecha_Hora: any, Estado_Fisico: string, Estatura: string) {
        this.Id = Id;
        this.Historia_Clinica_Id = Historia_Clinica_Id;
        this.Temperatura = Temperatura;
        this.Respiracion = Respiracion;
        this.Pulso = Pulso;
        this.Tension_Arterial = Tension_Arterial;
        this.Saturacion_Oxigeno = Saturacion_Oxigeno;
        this.Fecha_Hora = Fecha_Hora;
        this.Estado_Fisico = Estado_Fisico;
        this.Estatura = Estatura;
    }
}

    
