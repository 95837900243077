import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { PacienteService } from 'src/app/services/paciente.service';

declare var Treant: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-form-familiograma',
  templateUrl: './form-familiograma.component.html',
  styles: []
})
export class FormFamiliogramaComponent implements OnInit {

  private tree_structure: any = {
    chart: {
      container: "#custom-colored",
      levelSeparation: 45,
  
      rootOrientation: "WEST",
  
      nodeAlign: "BOTTOM",
  
      connectors: {
        type: "step",
        style: {
          "stroke-width": 2
        }
      },
      node: {
        HTMLclass: "big-commpany"
      }
    },
    nodeStructure: {
      text: { name: "CEO" },
		connectors: {
			style: {
				'stroke': '#bbb',
				'arrow-end': 'oval-wide-long'
			}
		},
		children: [
			{
				text: { name: "Account" },
				stackChildren: true,
				connectors: {
					style: {
						'stroke': '#8080FF',
						'arrow-end': 'block-wide-long'
					}
				},
				children: [
					{
						text: {name: "Receptionist"},
						HTMLclass: "reception"
					},
					{
						text: {name: "Author"}
					}
				]
			},
			{
				text: { name: "Operation Manager" },
				connectors: {
					style: {
						'stroke': '#bbb',
						"stroke-dasharray": "- .", //"", "-", ".", "-.", "-..", ". ", "- ", "--", "- .", "--.", "--.."
						'arrow-start': 'classic-wide-long'
					}
				},
				children: [
					{
						text: {name: "Manager I"},
						connectors: {
							style: {
								stroke: "#00CE67"
							}
						},
						children: [
							{
								text: {name: "Worker I"}
							},
							{
								pseudo: true,
								connectors: {
									style: {
										stroke: "#00CE67"
									}
								},
								children: [
									{
										text: {name: "Worker II"}
									}
								]
							},
							{
								text: {name: "Worker III"}
							}
						]
					},
					{
						text: {name: "Manager II"},
						connectors: {
							type: "curve",
							style: {
								stroke: "#50688D"
							}
						},
						children: [
							{
								text: {name: "Worker I"}
							},
							{
								text: {name: "Worker II"}
							}
						]
					},
					{
						text: {name: "Manager III"},
						connectors: {
							style: {
								'stroke': '#FF5555'
							}
						},
						children: [
							{
								text: {name: "Worker I"}
							},
							{
								pseudo: true,
								connectors: {
									style: {
										'stroke': '#FF5555'
									}
								},
								children: [
									{
										text: {name: "Worker II"}
									},
									{
										text: {name: "Worker III"}
									}
								]
							},
							{
								text: {name: "Worker IV"}
							}
						]
					}
				]
			},
			{
				text: { name: "Delivery Manager" },
				stackChildren: true,
				connectors: {
					stackIndent: 30,
					style: {
						'stroke': '#E3C61A',
						'arrow-end': 'block-wide-long'
					}
				},
				children: [
					{
						text: {name: "Driver I"}
					},
					{
						text: {name: "Driver II"}
					},
					{
						text: {name: "Driver III"}
					}
				]
			}
		]
    }
    // nodeStructure: {
    //     text: {
    //         name: { val: "Djokovic, Novak", href: "http://www.atpworldtour.com/Tennis/Players/Top-Players/Novak-Djokovic.aspx" }
    //     },
    //     HTMLclass: "animal",
    //     image: "/assets/img/heads/1.jpg",
    //     children: []
    // }
  }

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 1;
  pagina = 1;
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;

  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogoSrv: CatalogoService
  ) { }

  ngOnInit() {
    (() => { Treant(this.tree_structure) })();
  }

  guardar(forma: NgForm) {
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
