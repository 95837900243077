export class FichaIdentificacion {
    Id: number;
    Nombre: string;
    Apellido_Paterno: string;
    Apellido_Materno: string;
    Fecha_Nacimiento: any;
    Hora: any;
    Nacionalidad: string;
    Grupo_Sanguineo: string;
    Diestro: boolean;
    Sexo_Genetico: any;
    Medico_Cabecera: string;
    Medico_Solicitante: string;
    Idioma: string;
    Escolaridad_Id: number;
    Escolaridad_Dsc: string;
    Informante: string;
    Parentesco_Id: number;
    Parentesco_Dsc: string;
    Metodo_Aprendizaje: any;
    Obstaculo_Aprendizaje: any;
    Obstaculo_Aprendizaje_Dsc: string;
    Necesidad: any;
    Necesidad_Cultural: string;
    Necesidad_Espiritual: string;
    Seguro_Casa: any;
    Calle: string;
    Numero_Exterior: string;
    Numero_Interior: string;
    Colonia: string;
    Alcaldia: string;
    Estado: string;
    Municipio: string;
    CP: string;
    Religion: string;
    Religion_Id: number;
    CURP: string;
    Lugar_Elaboracion_Id: number;
    Lugar_Elaboracion_Dsc: string;
    Sin_Significativos: boolean;
    Antecedentes_Desconocidos: boolean;
    Enfermedades_Geneticas_Especificar: string;

    constructor(
        Id: number,
        Nombre: string,
        Apellido_Paterno: string,
        Apellido_Materno: string,
        Fecha_Nacimiento: any,
        Hora: any,
        Nacionalidad: string,
        Grupo_Sanguineo: string,
        Diestro: boolean,
        Sexo_Genetico: string,
        Medico_Cabecera: string,
        Medico_Solicitante: string,
        Idioma: string,
        Escolaridad_Id: number,
        Escolaridad_Dsc: string,
        Informante: string,
        Parentesco_Id: number,
        Parentesco_Dsc: string,
        Metodo_Aprendizaje: string,
        Obstaculo_Aprendizaje: string,
        Necesidad_Cultural: string,
        Necesidad_Espiritual: string,
        Seguro_Casa: string,
        Calle: string,
        Numero_Exterior: string,
        Numero_Interior: string,
        Colonia: string,
        Alcaldia: string,
        Estado: string,
        CP: string,
        Religion: string,
        CURP: string,
        Lugar_Elaboracion_Id: number,
        Lugar_Elaboracion_Dsc: string,
    ) {
        this.Id = Id;
        this.Nombre = Nombre;
        this.Apellido_Paterno = Apellido_Paterno;
        this.Apellido_Materno = Apellido_Materno;
        this.Fecha_Nacimiento = Fecha_Nacimiento;
        this.Sexo_Genetico = Sexo_Genetico;
        this.Hora = Hora;
        this.Nacionalidad = Nacionalidad;
        this.Grupo_Sanguineo = Grupo_Sanguineo;
        this.Diestro = Diestro;
        this.Sexo_Genetico = Sexo_Genetico;
        this.Medico_Cabecera = Medico_Cabecera;
        this.Medico_Solicitante = Medico_Solicitante;
        this.Idioma = Idioma;
        this.Escolaridad_Id = Escolaridad_Id;
        this.Escolaridad_Dsc = Escolaridad_Dsc;
        this.Informante = Informante;
        this.Parentesco_Id = Parentesco_Id;
        this.Parentesco_Dsc = Parentesco_Dsc;
        this.Metodo_Aprendizaje = Metodo_Aprendizaje;
        this.Obstaculo_Aprendizaje = Obstaculo_Aprendizaje;
        this.Necesidad_Cultural = Necesidad_Cultural;
        this.Necesidad_Espiritual = Necesidad_Espiritual;
        this.Seguro_Casa = Seguro_Casa;
        this.Calle = Calle;
        this.Numero_Exterior = Numero_Exterior;
        this.Numero_Interior = Numero_Interior;
        this.Colonia = Colonia;
        this.Alcaldia = Alcaldia;
        this.Estado = Estado;
        this.CP = CP;
        this.Religion = Religion;
        this.CURP = CURP;
        this.Lugar_Elaboracion_Id = Lugar_Elaboracion_Id;
        this.Lugar_Elaboracion_Dsc = Lugar_Elaboracion_Dsc;
    }
}