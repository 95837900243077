import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HistorialService } from 'src/app/services/historial.service';

@Component({
  selector: 'app-index-historia-clinica',
  templateUrl: './index-historia-clinica.component.html',
  styles: []
})
export class IndexHistoriaClinicaComponent implements OnInit {

  page = 1;

  constructor(
    public router: Router,
    public _historialSrv: HistorialService
  ) { }

  ngOnInit() {
  }

  link(page: number) {
    this.page = page;
  }

}
