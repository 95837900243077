export class FichaClinica {
    Expediente_Medico_Id: number;
    Persona_Id: number;
    Nombre: string;
    Edad: number;
    Genero: string;
    Estatura: string;
    Fecha_Nacimiento: any;
    Tipo_Sangre: string;
    CURP: string;
    Encamado: string;
    Lugar_Elaboracion_Id: number;
    Calle: string;
    Num_Int: string;
    Num_Ext: string;
    Colonia: string;
    Medico_Tratante: string;
    Responsable_Legal: string;
    Cuidador: string;
    Hospital_Procedencia: string;
    Diagnoticos: string;
    Terapeutica_Empleada: any[];
    Estudios_Realizados: string;
    Profesionales_Manejo: string;
    Domicilio_Ubicacion: string;

    constructor(Expediente_Medico_Id: number, Persona_Id: number, Nombre: string, Edad: number, Genero: string, Estatura: string, Fecha_Nacimiento: any, Tipo_Sangre: string, CURP: string, Encamado: string, Lugar_Elaboracion_Id: number, Calle: string, Num_Int: string, Num_Ext: string, Colonia: string, Medico_Tratante: string, Responsable_Legal: string, Cuidador: string, Hospital_Procedencia: string, Diagnoticos: string, Terapeutica_Empleada: any[], Estudios_Realizados: string, Profesionales_Manejo: string, Domicilio_Ubicacion: string) {
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Persona_Id = Persona_Id;
        this.Nombre = Nombre;
        this.Edad = Edad;
        this.Genero = Genero;
        this.Estatura = Estatura;
        this.Fecha_Nacimiento = Fecha_Nacimiento;
        this.Tipo_Sangre = Tipo_Sangre;
        this.CURP = CURP;
        this.Encamado = Encamado;
        this.Lugar_Elaboracion_Id = Lugar_Elaboracion_Id;
        this.Calle = Calle;
        this.Num_Int = Num_Int;
        this.Num_Ext = Num_Ext;
        this.Colonia = Colonia;
        this.Medico_Tratante = Medico_Tratante;
        this.Responsable_Legal = Responsable_Legal;
        this.Cuidador = Cuidador;
        this.Hospital_Procedencia = Hospital_Procedencia;
        this.Diagnoticos = Diagnoticos;
        this.Terapeutica_Empleada = Terapeutica_Empleada;
        this.Estudios_Realizados = Estudios_Realizados;
        this.Profesionales_Manejo = Profesionales_Manejo;
        this.Domicilio_Ubicacion = Domicilio_Ubicacion;
    }

}