
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVICIOS, OPTIONS, HOJAFRONTAL, FICHACLINICA, NOTAEVOLUCION, NOTAEGRESO } from '../services/url.servicios';
import { map, timeoutWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MensajeService } from './mensajes.service';
import { Router } from '@angular/router';
import { HojaFrontal } from '../class/hoja-frontal.class';
import { FichaClinica } from '../class/ficha-clinica.class';
import { NotaEvolucion } from '../class/nota-evolucion.class';
import { HojaIndicaciones } from '../class/hoja-indicaciones.class';
import { NotaEgreso } from '../class/nota-egreso.class';

@Injectable()
export class ExpedienteService {

    ficha: number = 1;
    form_1: HojaFrontal = new HojaFrontal(0, 0, '', 0, '', '', '', '', '', '', '', '', '', '', null, null, null, '', 0, '', [], [], []);
    form_2: FichaClinica = new FichaClinica(0, 0, '', 0, '', '', '', '', '', '', 0, '', '', '', '', '', '', '', '', '', [], '', '', '');
    form_3: NotaEvolucion = new NotaEvolucion(0, 0, null, '', '', '', '', 0, '', [], '', '', [], '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
    form_4: HojaIndicaciones = new HojaIndicaciones(0, 0, null, '', '', '', '', '', '', '', '', '', '', '');
    form_5: NotaEgreso = new NotaEgreso(0, 0, null, '', '', 0, 0, '', '', '', '', '', null, null, '', 0, '', '', '', '', '', '', '', []);

    constructor(
        public http: Http,
        public _mensajeSrv: MensajeService,
        public router: Router,
    ) {

    }

    consultar_hojafrontal(Paciente_Id: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${HOJAFRONTAL}?Paciente_Id=${Paciente_Id}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (data.Data.Fecha_Ingreso_1 && data.Data.Fecha_Ingreso_1.length > 0) {
                            data.Data.Fecha_Ingreso_1 = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Ingreso_1.split('/')[2]),
                                    month: Number(data.Data.Fecha_Ingreso_1.split('/')[1]),
                                    day: Number(data.Data.Fecha_Ingreso_1.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Ingreso_1.split('/')[2]), Number(data.Data.Fecha_Ingreso_1.split('/')[1]) - 1, Number(data.Data.Fecha_Ingreso_1.split('/')[0])),
                            }
                        }
                        if (data.Data.Fecha_Egreso && data.Data.Fecha_Egreso.length > 0) {
                            data.Data.Fecha_Egreso = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Egreso.split('/')[2]),
                                    month: Number(data.Data.Fecha_Egreso.split('/')[1]),
                                    day: Number(data.Data.Fecha_Egreso.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Egreso.split('/')[2]), Number(data.Data.Fecha_Egreso.split('/')[1]) - 1, Number(data.Data.Fecha_Egreso.split('/')[0])),
                            }
                        }

                        if (data.Data.Asistencias) {
                            data.Data.Asistencias.forEach(element => {
                                element.Fecha = {
                                    date:
                                    {
                                        year: Number(element.Fecha.split('/')[2]),
                                        month: Number(element.Fecha.split('/')[1]),
                                        day: Number(element.Fecha.split('/')[0])
                                    },
                                    jsdate: new Date(Number(element.Fecha.split('/')[2]), Number(element.Fecha.split('/')[1]) - 1, Number(element.Fecha.split('/')[0])),
                                }
                            });
                        }
                        if (data.Data.Consultas) {
                            data.Data.Consultas.forEach(element => {
                                element.Fecha_Alta = {
                                    date:
                                    {
                                        year: Number(element.Fecha_Alta.split('/')[2]),
                                        month: Number(element.Fecha_Alta.split('/')[1]),
                                        day: Number(element.Fecha_Alta.split('/')[0])
                                    },
                                    jsdate: new Date(Number(element.Fecha_Alta.split('/')[2]), Number(element.Fecha_Alta.split('/')[1]) - 1, Number(element.Fecha_Alta.split('/')[0])),
                                };
                                element.Fecha_Baja = {
                                    date:
                                    {
                                        year: Number(element.Fecha_Baja.split('/')[2]),
                                        month: Number(element.Fecha_Baja.split('/')[1]),
                                        day: Number(element.Fecha_Baja.split('/')[0])
                                    },
                                    jsdate: new Date(Number(element.Fecha_Baja.split('/')[2]), Number(element.Fecha_Baja.split('/')[1]) - 1, Number(element.Fecha_Baja.split('/')[0])),
                                }
                            });
                        }

                        resolve(data.Data);
                    } else {
                        resolve(null);
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_hojafrontal() {
        this._mensajeSrv.cargando();
        this.form_1.Fecha_Ingreso_1 = this.form_1.Fecha_Ingreso_1.formatted;
        this.form_1.Fecha_Egreso = this.form_1.Fecha_Egreso.formatted;

        this.form_1.Asistencias.forEach(element => {
            if (element.Fecha && element.Fecha.formatted) {
                element.Fecha = element.Fecha.formatted;
            } else if (element.Fecha && element.Fecha.date) {
                element.Fecha = `${element.Fecha.date.day}/${element.Fecha.date.month}/${element.Fecha.date.year}`;
            }
        });
        this.form_1.Consultas.forEach(element => {
            if (element.Fecha_Alta && element.Fecha_Baja && element.Fecha_Alta.formatted && element.Fecha_Baja.formatted) {
                element.Fecha_Alta = element.Fecha_Alta.formatted;
                element.Fecha_Baja = element.Fecha_Baja.formatted;
            } else if (element.Fecha_Alta && element.Fecha_Baja && element.Fecha_Alta.date && element.Fecha_Baja.date) {
                element.Fecha_Alta = `${element.Fecha_Alta.date.day}/${element.Fecha_Alta.date.month}/${element.Fecha_Alta.date.year}`;
                element.Fecha_Baja = `${element.Fecha_Baja.date.day}/${element.Fecha_Baja.date.month}/${element.Fecha_Baja.date.year}`;
            }
        });

        let promesa = new Promise((resolve, reject) => {
            // resolve();
            let url = `${URL_SERVICIOS}${HOJAFRONTAL}`;
            this.http.post(url, this.form_1, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (output.Resultado) {
                        this._mensajeSrv.correctamente(output.Mensaje)
                            .then(() => {
                                resolve(output.Id);
                            });
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    consultar_fichaclinica(Paciente_Id: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${FICHACLINICA}?Paciente_Id=${Paciente_Id}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (data.Data.Fecha_Nacimiento && data.Data.Fecha_Nacimiento.length > 0) {
                            data.Data.Fecha_Nacimiento = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Nacimiento.split('/')[2]),
                                    month: Number(data.Data.Fecha_Nacimiento.split('/')[1]),
                                    day: Number(data.Data.Fecha_Nacimiento.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Nacimiento.split('/')[2]), Number(data.Data.Fecha_Nacimiento.split('/')[1]) - 1, Number(data.Data.Fecha_Nacimiento.split('/')[0])),
                            }
                        }

                        resolve(data);
                    }
                    else {
                        resolve(null);
                    }


                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_fichaclinica() {
        this._mensajeSrv.cargando();
        this.form_2.Fecha_Nacimiento = this.form_2.Fecha_Nacimiento.formatted;
        console.log(this.form_2.Fecha_Nacimiento);
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${FICHACLINICA}`;
            this.http.post(url, this.form_2, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    console.log(output);
                    if (output.Resultado) {
                        this._mensajeSrv.correctamente(output.Mensaje)
                            .then(() => {
                                resolve(output.Id);
                            });
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    consultar_nota_evolucion(Paciente_Id: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${NOTAEVOLUCION}?Paciente_Id=${Paciente_Id}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (data.Data.Fecha_Nacimiento && data.Data.Fecha_Nacimiento.length > 0) {
                            data.Data.Fecha_Nacimiento = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Nacimiento.split('/')[2]),
                                    month: Number(data.Data.Fecha_Nacimiento.split('/')[1]),
                                    day: Number(data.Data.Fecha_Nacimiento.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Nacimiento.split('/')[2]), Number(data.Data.Fecha_Nacimiento.split('/')[1]) - 1, Number(data.Data.Fecha_Nacimiento.split('/')[0])),
                            }
                        }

                        resolve(data);
                    }
                    else {
                        resolve(null);
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_nota_evolucion() {
        this._mensajeSrv.cargando();
        if (this.form_3.Fecha_Nacimiento.formatted) {
            this.form_3.Fecha_Nacimiento = this.form_3.Fecha_Nacimiento.formatted;
        } else {
            this.form_3.Fecha_Nacimiento = `${this.form_3.Fecha_Nacimiento.date.year}-${this.form_3.Fecha_Nacimiento.date.month}-${this.form_3.Fecha_Nacimiento.date.day}`;
        }

        let promesa = new Promise((resolve, reject) => {
            // resolve();
            let url = `${URL_SERVICIOS}${NOTAEVOLUCION}`;
            this.http.post(url, this.form_3, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (output.Resultado) {
                        this._mensajeSrv.correctamente(output.Mensaje)
                            .then(() => {
                                resolve(output.Id);
                            });
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    consultar_nota_egreso(Paciente_Id: number) {
        this._mensajeSrv.cargando();
        let promesa = new Promise((resolve, reject) => {
            let url = `${URL_SERVICIOS}${NOTAEGRESO}?Paciente_Id=${Paciente_Id}`;
            this.http.get(url, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    let data = JSON.parse(output);
                    if (data.Resultado) {
                        if (data.Data.Fecha_Ingreso && data.Data.Fecha_Ingreso.length > 0) {
                            data.Data.Fecha_Ingreso = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Ingreso.split('/')[2]),
                                    month: Number(data.Data.Fecha_Ingreso.split('/')[1]),
                                    day: Number(data.Data.Fecha_Ingreso.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Ingreso.split('/')[2]), Number(data.Data.Fecha_Ingreso.split('/')[1]) - 1, Number(data.Data.Fecha_Ingreso.split('/')[0])),
                            }
                        }
                        if (data.Data.Fecha_Egreso && data.Data.Fecha_Egreso.length > 0) {
                            data.Data.Fecha_Egreso = {
                                date:
                                {
                                    year: Number(data.Data.Fecha_Egreso.split('/')[2]),
                                    month: Number(data.Data.Fecha_Egreso.split('/')[1]),
                                    day: Number(data.Data.Fecha_Egreso.split('/')[0])
                                },
                                jsdate: new Date(Number(data.Data.Fecha_Egreso.split('/')[2]), Number(data.Data.Fecha_Egreso.split('/')[1]) - 1, Number(data.Data.Fecha_Egreso.split('/')[0])),
                            }
                        }
                        resolve(data);
                    }
                    else {
                        resolve(null);
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

    guardar_nota_egreso() {
        this._mensajeSrv.cargando();
        if (this.form_5.Fecha_Ingreso.formatted) {
            this.form_5.Fecha_Ingreso = this.form_5.Fecha_Ingreso.formatted;
        } else if (this.form_5.Fecha_Ingreso.date) {
            this.form_5.Fecha_Ingreso = `${this.form_5.Fecha_Ingreso.date.year}-${this.form_5.Fecha_Ingreso.date.month}-${this.form_5.Fecha_Ingreso.date.day}`;
        }
        if (this.form_5.Fecha_Egreso.formatted) {
            this.form_5.Fecha_Egreso = this.form_5.Fecha_Egreso.formatted;
        } else if (this.form_5.Fecha_Egreso.date) {
            this.form_5.Fecha_Egreso = `${this.form_5.Fecha_Egreso.date.year}-${this.form_5.Fecha_Egreso.date.month}-${this.form_5.Fecha_Egreso.date.day}`;
        }

        let promesa = new Promise((resolve, reject) => {
            // resolve();
            let url = `${URL_SERVICIOS}${NOTAEGRESO}`;
            this.http.post(url, this.form_5, OPTIONS)
                .pipe(
                    map(resp => resp.json()),
                    timeoutWith(10000, Observable.throw(new Error("No se puede completar la acción por un error interno, inténtelo más tarde")))
                )
                .subscribe(output => {
                    this._mensajeSrv.cerrar();
                    // let data = JSON.parse(output);
                    if (output.Resultado) {
                        this._mensajeSrv.correctamente(output.Mensaje)
                            .then(() => {
                                resolve(output.Id);
                            });
                    }
                }, err => { this._mensajeSrv.cerrar(); });
        });
        return promesa;
    }

}
