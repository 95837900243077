import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  constructor(
    public router: Router,
    public _usuarioSrv: UsuarioService
  ) { }

  ngOnInit() {
    // this.router.navigateByUrl('/historial');
  }

  ingresar() {
    this._usuarioSrv.consultar(this._usuarioSrv.usuario.Usuario, this._usuarioSrv.usuario.Contrasena)
      .then((resultado: boolean) => {
        console.log(resultado);

        if (resultado) {
          this.router.navigateByUrl('/historial');
        }
      });
  }

}
