export class HojaIndicaciones {
    Expediente_Medico_Id: number;
    Persona_Id: number;
    Fecha: any;
    Hora: string;
    Tipo_Alimentacion: string;
    Soluciones: string;
    Medicamentos: string;
    Cuidados_Generales: string;
    Examenes_Solicitados: string;
    Interconsultas: string;
    Indicaciones: string;
    Medico_Elabora: string;
    Cedula: string;
    Logo: string;

    constructor(Expediente_Medico_Id: number, Persona_Id: number, Fecha: any, Hora: string, Tipo_Alimentacion: string, Soluciones: string, Medicamentos: string, Cuidados_Generales: string, Examenes_Solicitados: string, Interconsultas: string, Indicaciones: string, Medico_Elabora: string, Cedula: string, Logo: string) {
        this.Expediente_Medico_Id = Expediente_Medico_Id;
        this.Persona_Id = Persona_Id;
        this.Fecha = Fecha;
        this.Hora = Hora;
        this.Tipo_Alimentacion = Tipo_Alimentacion;
        this.Soluciones = Soluciones;
        this.Medicamentos = Medicamentos;
        this.Cuidados_Generales = Cuidados_Generales;
        this.Examenes_Solicitados = Examenes_Solicitados;
        this.Interconsultas = Interconsultas;
        this.Indicaciones = Indicaciones;
        this.Medico_Elabora = Medico_Elabora;
        this.Cedula = Cedula;
        this.Logo = Logo;
    }

}