import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/services/paciente.service';
import { NgForm } from '@angular/forms';
import { myDatePickerOptions } from 'src/app/services/url.servicios';
import { CatalogoService } from 'src/app/services/catalogo.service';

@Component({
  selector: 'app-form-ficha-identificacion',
  templateUrl: './form-ficha-identificacion.component.html',
  styles: []
})
export class FormFichaIdentificacionComponent implements OnInit {

  myDatePickerOptions = myDatePickerOptions;
  placeholder_1: String = 'Fecha de nacimiento';
  total = 2;
  pagina = 1;
  parentescos = [];
  escolaridad = [];
  religion = [];
  elaboracion = [];
  estados = [];
  municipios = [];
  verNecesidad = false;
  verMunicipio = false;

  constructor(
    public _pacienteSrv: PacienteService,
    public _catalogoSrv: CatalogoService
  ) { }

  ngOnInit() {
    this._catalogoSrv.parentesco()
      .then((data: any[]) => {
        this.parentescos.push(...data);
      });
    this._catalogoSrv.escolaridad()
      .then((data: any[]) => {
        this.escolaridad.push(...data);
      });
    this._catalogoSrv.religion()
      .then((data: any[]) => {
        this.religion.push(...data);
      });
    this._catalogoSrv.elaboracion()
      .then((data: any[]) => {
        this.elaboracion.push(...data);
      });
    this._catalogoSrv.estados()
      .then((data: any[]) => {
        this.estados.push(...data);
      });
  }

  guardar(forma: NgForm) {
    this._pacienteSrv.guardar()
      .then((Id: number) => {
        this._pacienteSrv.consultar(Id)
          .then((data: any[]) => {
            if (data.length > 0) {
              this._pacienteSrv.paciente = data[0];
            }
          });
      });
  }

  validar(forma: NgForm): boolean {
    return forma.invalid;
  }

  handleChangeNecesidad(event) {
    this.verNecesidad = Number(event.target.value) === 1;
  }

  handleChangeEstado(event) {
    this.verMunicipio = event !== '';
    this._catalogoSrv.municipios(event)
      .then((data: any[]) => {
        this.municipios = [];
        this.municipios.push(...data);
      });
  }

  prevPaginado(pagina: number) {
    if (this.pagina - pagina < 1) {
      this.pagina = 1;
    } else {
      this.pagina = this.pagina - pagina;
    }
  }

  nextPaginado(pagina: number) {
    if (this.pagina + pagina > this.total) {
      this.pagina = this.total;
    } else {
      this.pagina = this.pagina + pagina;
    }
  }

}
